import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "./components/Loader";
import { round } from "./redux/helpers/Math";
import {
  BUY_MARKET_PRICE,
  SELL_MARKET_PRICE,
  SET_ORDER_BOOK
} from "./redux/constant";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import $ from "jquery";
export default function OrderSystemTab(props) {
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
      "&::before": {
        backgroundColor: theme.palette.common.black,
        border: "1px solid #f5f5f5"
      }
    },

    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "transparent",
      // color: "#000",

      maxWidth: 300
      // fontSize: theme.typography.pxToRem(14),
      // border: "1px solid #f5f5f5"
    }
  }));

  const [activeTab, setActiveTab] = useState(0);
  const [sarr, setsarr] = useState([]);
  const [price, setprice] = React.useState(0);
  const [barr, setbarr] = useState([]);
  const {
    coins,
    paired_curency_price,
    buy_order_book,
    sell_order_book,
    order_book_loading
  } = useSelector((state) => state.coinDBReducer);
  const dispatch = useDispatch();
  const coin = props?.match?.params?.id?.split("-");
  const coinname = coin[0] + coin[1];
  const { webData } = useSelector((state) => state.websiteDBReducer);

  function reverseArr(input) {
    let ret = new Array();
    for (var i = input.length - 1; i >= 0; i--) {
      let updatearr = {
        compare_currency: input[i].compare_currency,
        currency_type: input[i].currency_type,
        raw_price: round(input[i].raw_price),
        volume: round(input[i].volume)
      };
      if (round(updatearr.volume) > 0) ret.push(input[i]);
    }
    return ret;
  }
  var test = $("#scrollbottom").height();
  $("#scrollbottom").scrollTop(test);
  useEffect(() => {
    Object.values(coins)?.map((d, i) => {
      if (d.symbol === coin[0].toUpperCase()) {
        setprice(
          Number(d?.current_price) /
            Number(
              paired_curency_price
                ? paired_curency_price[coin[1].toUpperCase()]
                  ? paired_curency_price[coin[1].toUpperCase()]
                  : 1
                : 1
            )
        );
      }
    });
  });

  useEffect(() => {
    if (buy_order_book || sell_order_book) {
      // console.log("test: ", buy_order_book, sell_order_book);
      // Object.keys(buy_order_book).map((item) => {
      // console.log("inbyob: ", item, )
      if (buy_order_book[coinname]) {
        let tarr = reverseArr(
          buy_order_book[coinname].sort((a, b) => a.raw_price - b.raw_price)
        );
        setbarr(tarr);
      } else {
        setbarr();
      }
      // });

      // Object.keys(sell_order_book).map((item) => {
      if (sell_order_book[coinname]) {
        let t1arr = reverseArr(
          sell_order_book[coinname].sort((a, b) => a.raw_price - b.raw_price)
        );
        setsarr(t1arr);
      } else {
        setsarr();
      }
      // });
    }
  }, [buy_order_book, sell_order_book, coinname]);

  function getPercentage(cv, arr) {
    // console.log("perc arr: ", arr);
    let tv = 0;
    for (let i = 0; i < arr.length; i++) {
      tv += parseFloat(arr[i].volume);
    }
    return (Number(cv) * 100) / Number(tv);
  }

  useEffect(() => {
    dispatch({ type: SET_ORDER_BOOK, order_book_loading: false });
  }, []);
  // console.log("tarr: ", barr, sarr);
  return (
    <>
      <div className="card custom_scrollbar">
        <div className="card-header">Order Book</div>
        <div>
          <div className="">
            <div className="min_height416">
              {order_book_loading ? (
                <Loader />
              ) : buy_order_book?.length != 0 || sell_order_book.length != 0 ? (
                <>
                  <table className="order-book-table exchange_tbl">
                    <thead className="sticky_thead">
                      <tr>
                        <th className="">
                          <h6>Volume</h6>
                        </th>
                        <th className="text-end">
                          <h6>Buy Price</h6>
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <div
                    className="sell_minheight custom_scrollbar"
                    id="scrollbottom"
                  >
                    <table className="order-book-table exchange_tbl">
                      <tbody className="sell">
                        {sarr
                          ? sarr.map((d, index) => (
                              <HtmlTooltip
                                title={
                                  <React.Fragment>
                                    <div className="tooltip_box">
                                      <div className="d-flex justify-content-between mb-1">
                                        <div> Price</div>
                                        <div>{round(d.raw_price)}</div>
                                      </div>
                                      <div className="d-flex justify-content-between mb-1">
                                        <div>Amount</div>
                                        <div>{round(d.volume)}</div>
                                      </div>
                                      <div className="d-flex justify-content-between mb-1">
                                        <div>Total</div>
                                        <div>
                                          {round(d.volume) * round(d.raw_price)}
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                }
                                placement="left"
                              >
                                <tr
                                  key={index}
                                  onClick={() => {
                                    dispatch({
                                      type: SELL_MARKET_PRICE,
                                      data: {
                                        marketprice: d.raw_price,
                                        marketvolume: d.volume,
                                        active: 0
                                      }
                                    });
                                  }}
                                >
                                  <td className="text-red">
                                    {round(d.volume)}
                                  </td>
                                  <td className="text-end">
                                    {round(d.raw_price)}
                                  </td>

                                  <div
                                    className="filler"
                                    style={{
                                      width: getPercentage(d.volume, sarr) + "%"
                                    }}
                                  ></div>
                                </tr>
                              </HtmlTooltip>
                            ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                  <div className="buy_minheight custom_scrollbar table_on_hover">
                    <div className="table_center_div sticky_thead">
                      <div className="text-green fs-14">{round(price)}</div>{" "}
                    </div>

                    <table
                      className="order-book-table exchange_tbl "
                      style={{ height: "fit-content" }}
                    >
                      {/* <thead className="sticky_thead">
                        <tr>
                          <th colSpan={2}>
                            <h6>
                              <span className="text-green fw-bold">
                                {round(price)}
                              </span>{" "}
                            </h6>
                          </th>
                        
                        </tr>
                      </thead> */}
                      <tbody className="buy">
                        {buy_order_book
                          ? barr?.map((d, index) => (
                              <HtmlTooltip
                                title={
                                  <React.Fragment>
                                    <div className="tooltip_box">
                                      <div className="d-flex justify-content-between mb-1">
                                        <div>Price</div>
                                        <div>{round(d.raw_price)}</div>
                                      </div>
                                      <div className="d-flex justify-content-between mb-1">
                                        <div>Amount</div>
                                        <div>{round(d.volume)}</div>
                                      </div>
                                      <div className="d-flex justify-content-between mb-1">
                                        <div>Total</div>
                                        <div>
                                          {round(d.volume) * round(d.raw_price)}
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                }
                                placement="left"
                              >
                                <tr
                                  key={index}
                                  onClick={() => {
                                    dispatch({
                                      type: BUY_MARKET_PRICE,
                                      data: {
                                        marketprice: d.raw_price,
                                        marketvolume: d.volume,
                                        active: 1
                                      }
                                    });
                                  }}
                                >
                                  <td className="text-green">
                                    {round(d.volume)}
                                  </td>
                                  <td className="text-end">
                                    {round(d.raw_price)}
                                  </td>
                                  <div
                                    className="filler"
                                    style={{
                                      width: getPercentage(d.volume, barr) + "%"
                                    }}
                                  ></div>
                                </tr>
                              </HtmlTooltip>
                            ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ flex: 1, height: "328px" }}
                >
                  <p className="mdfthemetxt">No record found</p>
                </div>
              )}
            </div>
          </div>

          {/* <div
            className={`tab-pane fade ${activeTab == 1 ? "active show" : ""}`}
          >
            <div className="order-book-container">
              {order_book_loading ? (
                <Loader />
              ) : buy_order_book?.length != 0 || sell_order_book.length != 0 ? (
                <>
                  <table
                    className="order-book-table order-book-table--open order-book-table--left"
                    style={{ height: "fit-content" }}
                  >
                    <colgroup>
                      <col width="10%" />
                      <col width="40%" />
                      <col width="50%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          <h6>Volume</h6>
                        </th>
                        <th className="text-end">
                          <h6>Buy Price</h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="buy">
                      {buy_order_book
                        ? barr?.map((d, index) => (
                            <tr
                              key={index}
                              onClick={() => {
                                dispatch({
                                  type: BUY_MARKET_PRICE,
                                  data: {
                                    marketprice: d.raw_price,
                                    marketvolume: d.volume,
                                    active: 1
                                  }
                                });
                              }}
                            >
                              <td className="status-dot-cell">
                                <div></div>
                              </td>
                              <td className="text-green">
                                {round(d.volume)}
                              </td>
                              <td>{round(d._id)}</td>
                              <div
                                className="filler"
                                style={{
                                  width: getPercentage(d.volume, barr) + "%"
                                }}
                              ></div>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                  <table
                    className="order-book-table order-book-table--open order-book-table--right"
                    style={{ height: "fit-content" }}
                  >
                    <colgroup>
                      <col width="50%" />
                      <col width="40%" />
                      <col width="10%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th className="text-uppercase">
                          <h6>Sell Price</h6>
                        </th>
                        <th className="text-uppercase">
                          <h6>Volume</h6>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="sell">
                      {sell_order_book
                        ? sarr?.map((d, index) => (
                            <tr
                              key={index}
                              onClick={() => {
                                dispatch({
                                  type: SELL_MARKET_PRICE,
                                  data: {
                                    marketprice: d.raw_price,
                                    marketvolume: d.volume,
                                    active: 0
                                  }
                                });
                              }}
                            >
                              <td className="text-red">{round(d._id)}</td>
                              <td>{round(d.volume)}</td>
                              <td className="status-dot-cell">
                                <div></div>
                              </td>
                              <div
                                className="filler"
                                style={{
                                  width: getPercentage(d.volume, sarr) + "%"
                                }}
                              ></div>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                </>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ flex: 1, height: "328px" }}
                >
                  <p className="mdfthemetxt">No record found</p>
                </div>
              )}
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
