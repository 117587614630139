import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import OrderSidebar from "./components/OrderSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import { PiFolderUserBold } from "react-icons/pi";
import { BiSolidCheckShield } from "react-icons/bi";
import { MdCurrencyExchange } from "react-icons/md";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { VscSettings } from "react-icons/vsc";
import { LuPlus, LuRefreshCw } from "react-icons/lu";
import { IoIosSearch } from "react-icons/io";
import P2PBuy from "./p2pPages/P2PAddPost";
import P2PTranding from "./p2pPages/P2PTranding";
import QuickBuy from "./p2pPages/QuickBuy";
import FiatDeposit from "./p2pPages/FiatDeposit";

const mockData = [
  {
    image:
      "https://images.pexels.com/photos/2182970/pexels-photo-2182970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    merchant: "Ahmet",
    orders: 252,
    successRate: "94.45%",
    online: "28 min ago",
    paymentMethods: ["Akbank ", "Denizbank", "İŞBANK"],
    price: "36.48",
    currency: "TRY",
    available: "$456.80 ",
    limit: "₺2,000  -  ₺  20,000",
    limitCurrency: "USDT",
    time: "15 min",
    per: "99.56%",
  },
  {
    image: "https://thispersondoesnotexist.com/",
    merchant: "Mustafa",
    orders: 252,
    successRate: "94.45%",
    online: "28 min ago",
    paymentMethods: ["Akbank ", "Denizbank"],
    price: "36.50",
    currency: "TRY",
    available: "$456.80 ",
    limit: "₺2,000  -  ₺20,000",
    limitCurrency: "USDT",
    time: "15 min",
    per: "99.56%",
  },
  {
    image:
      "https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    merchant: "Dilara",
    orders: 252,
    successRate: "94.45%",
    online: "28 min ago",
    paymentMethods: ["Akbank ", "Denizbank", "İŞBANK"],
    price: "36.42",
    currency: "TRY",
    available: "$456.80 ",
    limit: "₺2,000  -  ₺20,000",
    limitCurrency: "USDT",
    time: "15 min",
    per: "99.56%",
  },
  {
    image:
      "https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    merchant: "Abdul",
    orders: 252,
    successRate: "94.45%",
    online: "28 min ago",
    paymentMethods: ["Akbank ", "Denizbank", "İŞBANK"],
    price: "36.42",
    currency: "TRY",
    available: "$456.80 ",
    limit: "₺2,000  -  ₺20,000",
    limitCurrency: "USDT",
    time: "15 min",
    per: "99.56%",
  },
  {
    image:
      "https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    merchant: "Samuel",
    orders: 252,
    successRate: "94.45%",
    online: "28 min ago",
    paymentMethods: ["Akbank ", "Denizbank", "İŞBANK"],
    price: "36.42",
    currency: "TRY",
    available: "$456.80 ",
    limit: "₺2,000  -  ₺20,000",
    limitCurrency: "USDT",
    time: "15 min",
    per: "99.56%",
  },
];

const currencies = [
  {
    value: "USD",
    label: "USD",
    icon: <MdCurrencyExchange className="text-warning font-bold me-2" />,
  },
  {
    value: "EUR",
    label: "EUR",
    icon: <MdCurrencyExchange className="text-primary font-bold me-2" />,
  },
  {
    value: "GBP",
    label: "GBP",
    icon: <MdCurrencyExchange className="text-success font-bold me-2" />,
  },
];

const P2Porder = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("p2pTrading");
  const [showModal, setShowModal] = useState(false);

  //Modal function
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <Header />
      <div className="page-content " style={{ marginTop: "100px" }}>
        <main className="container mt-5">
          <div className="row">
            <div className="mb-3 w-full">
              {/* Tab Headers */}
              {/* <div className="d-flex justify-content-between ">
                <div className=" mb-4 text-white">
                  <div className="nav">
                    <div
                      className={`nav-item nav-link cursor-pointer px-4 py-2 ${
                        activeTab === "p2pTrading"
                          ? "active text-white fw-bold bold-border-bottom"
                          : "text-muted"
                      }`}
                      onClick={() => setActiveTab("p2pTrading")}
                      style={{ fontSize: "1.25rem" }} // Larger font size
                    >
                      P2P Trading
                    </div>
                    <div
                      className={`nav-item nav-link cursor-pointer px-4 py-2 ${
                        activeTab === "quickBuy"
                          ? "active text-white fw-bold bold-border-bottom"
                          : "text-muted"
                      }`}
                      onClick={() => setActiveTab("quickBuy")}
                      style={{ fontSize: "1.25rem" }}
                    >
                      Quick Buy
                    </div>
                    <div
                      className={`nav-item nav-link cursor-pointer px-4 py-2 ${
                        activeTab === "fiatDeposit"
                          ? "active text-white fw-bold bold-border-bottom"
                          : "text-muted"
                      }`}
                      onClick={() => setActiveTab("fiatDeposit")}
                      style={{ fontSize: "1.25rem" }}
                    >
                      Fiat Deposit
                    </div>
                  </div>
                </div>
              </div> */}

              {/* Tab Content */}
              <div className="tab-content">
                {activeTab === "p2pTrading" && (
                  <P2PTranding
                    mockData={mockData}
                    handleOpenModal={handleOpenModal}
                  />
                )}

                {/* tab 2 */}
                {activeTab === "quickBuy" && <QuickBuy />}

                {/* tab 3 */}

                {activeTab === "fiatDeposit" && (
                  <FiatDeposit currencies={currencies} />
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default P2Porder;
