
const formatDate = (item) => {
    const formattedDate = new Date(item).toLocaleString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });

  return formattedDate;
};

export default formatDate;