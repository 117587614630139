import React, { useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import Header from "../HomeComp/Header";
import P2PPostAd from "./P2PAddPost";
import P2PPostAd2 from "./P2PAddPost2";
import P2PPostAdd3 from "./P2PPostAdd3";
import Footer from "../HomeComp/Footer";

const StepperP2P = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const steps = [
    { label: "Set Type & Price" },
    { label: "Set Total Amount & Payment Method" },
    { label: "Set Remarks And Automatic Respomse " },
  ];

  return (
    <>
      <Header />

      <div className="container py-4" style={{ marginTop: "100px" }}>
        <div className=" d-flex justify-content-between align-items-center">
          <h2 className=" ">Post Normal Ad</h2>
        </div>
        {/* Stepper Component */}
        <Stepper
          styleConfig={{
            activeBgColor: "#552DBA",
            activeTextColor: "#ffffff",
            inactiveBgColor: "#808080",
            inactiveTextColor: "#000000",
            completedBgColor: "#fff",
            completedTextColor: "#00000",
            size: "2em",
          }}
          activeStep={activeStep}
          className="w-100"
        >
          {steps.map((step, index) => (
            <Step key={index} label={step.label} />
          ))}
        </Stepper>

        {/* Step Content */}
        <div style={{ margin: "20px 0" }}>
          <div className="col-7 m-auto">
            <div
              className="nav nav-tabs mb-4 border-0"
              id="nav-tab"
              role="tablist"
            >
              <button
                className="nav-link active w-50"
                id="buy-tab"
                data-bs-toggle="tab"
                data-bs-target="#buy"
                type="button"
                role="tab"
              >
                I want to buy
              </button>
              <button
                className="nav-link w-50"
                id="sell-tab"
                data-bs-toggle="tab"
                data-bs-target="#sell"
                type="button"
                role="tab"
              >
                I want to sell
              </button>
            </div>
            {activeStep === 0 && (
              <div>
                <P2PPostAd />
              </div>
            )}
            {activeStep === 1 && (
              <div>
                <P2PPostAd2 />
              </div>
            )}
            {activeStep === 2 && (
              <div>
                <P2PPostAdd3 />
              </div>
            )}
          </div>
        </div>

        {/* Navigation Buttons */}

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            onClick={handleBack}
            disabled={activeStep === 0}
            className="btn rounded-2 text-white"
            style={{
              width: "150px",
              height: "40px",
              background: "#1C2C60",
            }}
          >
            Back
          </button>
          <button
            onClick={handleNext}
            disabled={activeStep === steps.length - 1}
            className="btn rounded-2 text-white"
            style={{
              width: "150px",
              height: "40px",
              background: "#1C2C60",
            }}
          >
            {activeStep === steps.length - 1 ? "Submit" : "Next"}
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default StepperP2P;
