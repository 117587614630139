import React from "react";
import { BiSend } from "react-icons/bi";
import "../modals/p2p.css";
import ChatBox from "./payment-methods/ChatComponent";
import { useHistory } from "react-router-dom";

const CreateOrder1 = ({ handleNext }) => {
  const router = useHistory();
  return (
    <>
      <div className="container">
        <section>
          <h1>Order Created</h1>
          <p>Pay the seller within 15:00</p>
        </section>

        <div className="d-flex flex-column gap-4">
          <div className="d-flex justify-content-between align-items-center back-borders px-4 py-3">
            <section className="d-flex gap-4 align-items-center">
              <img
                src="https://thispersondoesnotexist.com/"
                alt="User"
                className="rounded-5"
                style={{ height: "30px" }}
              />
              <h4>Braganza</h4>
            </section>
            <button
              className="btn text-white px-4"
              style={{ width: "80px", height: "30px", background: "#552dba" }}
              onClick={handleNext}
            >
              Chat
            </button>
          </div>
          <div
            className="d-flex flex-column gap-3 back-borders p-4 col-12"
            style={{ minHeight: "400px", overflow: "auto" }}
          >
            <div className="border rounded-3 p-3">
              <h5>
                <span className="text-green">Buy</span> USDT
              </h5>
              <p className="d-flex justify-content-between">
                <span>Fiat Amount</span>
                <span>$100</span>
              </p>
              <p className="d-flex justify-content-between">
                <span>Price</span>
                <span>$1</span>
              </p>
              <p className="d-flex justify-content-between">
                <span>Receive Quantity</span>
                <span>100 USDT</span>
              </p>
              <p className="d-flex justify-content-between">
                <span>Total Quantity</span>
                <span>100 USDT</span>
              </p>
              <p className="d-flex justify-content-between">
                <span>Fee</span>
                <span>0.05000000 USDT</span>
              </p>
            </div>
            <div className="border rounded-3 p-3">
              <p className="d-flex justify-content-between">
                <span>Pay With</span>
                <span>Lightning UPI</span>
              </p>
              <p className="d-flex justify-content-between">
                <span>Name</span>
                <span>SHUBHAM SETHI</span>
              </p>
              <p className="d-flex justify-content-between">
                <span>Digital eRupee wallet VPA</span>
                <span>idfc</span>
              </p>
              <p className="d-flex justify-content-between">
                <span>You Pay </span>
                <span>100$</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateOrder1;
