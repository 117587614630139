import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./Header";
import Footer from "./Footer";
import { getRound } from "../redux/helpers/helper_functions";
import useHumanReadableNumber from "../../hooks/useHumanReadableNumber";
import { Sparklines, SparklinesCurve, SparklinesLine } from "react-sparklines";
import Loader from "../components/Loader";
import { NavLink } from "react-router-dom";

export default function Markets() {
  const formatNumber = useHumanReadableNumber();
  const [loader, setLoader] = useState(true);
  const humanReadableNumber = useHumanReadableNumber();
 const [tabSelect, setTabSelect]= useState("all")
  const convertStringToNumber = (input) => {
    const str = String(input);
    const cleanedString = str.replace(/,/g, '');
    return parseFloat(cleanedString);
  };
  function formatNumberWithCommas(number, locales = "en-US", options = {}) {
    const defaultOptions = {
      minimumFractionDigits: 3,
      maximumFractionDigits: 4,
    };
    const formatOptions = { ...defaultOptions, ...options };
    return new Intl.NumberFormat(locales, formatOptions).format(number);
  }

  AOS.init();
  const { coins, campare_currency } = useSelector((state) => state.coinDBReducer);
  let coins1 = Object.values(coins);

  const preferredOrder = ["BTC", "ETH", "XRP", "USDT", "SOL", "BNB", "DOGE"];

  useEffect(() => {
    if (coins1?.length === 0) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [coins]);

  // Sort coins1 based on preferredOrder
  coins1.sort((a, b) => {
    const indexA = preferredOrder.indexOf(a.symbol);
    const indexB = preferredOrder.indexOf(b.symbol);
    if (indexA === -1) return 1; // If a.symbol is not in preferredOrder, move it to the end
    if (indexB === -1) return -1; // If b.symbol is not in preferredOrder, move it to the end
    return indexA - indexB;
  });

  const market_child =
    coins1 &&
    coins1.map((item, index) => {
      let usdtprize = coins1 && coins1.find((item) => item.symbol === "USDT");
      if ( item.symbol !== 'USDT') {
        return (
          <tr style={{height:'60px'}} className="table_row_border ">
              <td>
                <img
                  src={item.icon}
                  alt="coin symbol"
                  className="market_coin_img"
                />
                {item.symbol}
                <span className="name_coin ms-2">{item.name}</span>
              </td>
              <td>
                $
                {formatNumberWithCommas(
                  getRound(item?.current_price / usdtprize?.current_price)
                )}
              </td>
              <td>
                <span
                 style={{color:item.direction === "up" ? "#00FF00 " : "#e2464a"}}
                >
                  {item.direction === "up" ? "+" : "-"}{" "}
                  {getRound(item.price_change_percentage_1h)}%{" "}
                  {item.direction === "up" ? (
                    <BsArrowUpShort />
                  ) : (
                    <BsArrowDownShort />
                  )}
                </span>
              </td>
              <td>
                $
                {formatNumber(
                  convertStringToNumber(item.market_cap) /
                    usdtprize?.current_price
                )}
              </td>
              <td className="text-end pe-5">
                <Sparklines>
                  <SparklinesLine color="" data={item.h_trend} />
                </Sparklines>

                <Sparklines data={item.h_trend}>
                  <SparklinesCurve
                    color={item.direction === "up" ? "#00FF00" : "#FF4500"}
                    style={{
                      strokeWidth: 2,
                      strokeLinejoin: "round",
                      strokeLinecap: "round",
                      filter: "drop-shadow(0 0 5px rgba(255, 255, 255, 0.7))",
                    }}
                  />
                </Sparklines>
              </td>
              <td>
                <NavLink
                 
                  to={`/exchange/${item.symbol.toLowerCase()}-${campare_currency}`}
                >
                  <button className="btn-pink btn px-4">
                  Trade
                  </button>
                </NavLink>
              </td>
            </tr>
        );
      }
      return null;
    });

  return (
    <>
      <Header />
      <div className="">
        <main className="">
          <div className="page-content" style={{ marginTop: '100px' }}>
            <div className="tab-content">
              <div className="tab-pane fade active show" id="all">
                <div className="spacer">
                  <div className="container">
                    <div className="para_heading">Market Trend</div>
                    <p className="mb-4 text-lgray">
                      Find promising coins and great opportunities!
                    </p>
                    {/* new code */}
                    <div class="tab_area market_table">
          <div class="container custom_pills">
            <div class="nav nav-pills" id="nav-tab" role="tablist">
              <div
               class={`nav-item nav-link   ${tabSelect == 'favourite' ? 'active':""}`}
               onClick={()=>setTabSelect('favourite')}
                data-toggle="tab"
              >
                <i className="fa-regular fa-star me-1"></i>Favourites
              </div>
              
              <div class={`nav-item nav-link   ${tabSelect == 'spot' ? 'active':""}`}href="#spot" data-toggle="tab"
               onClick={()=>setTabSelect('spot')}
              >
                Spot
              </div>
              <div
                class={`nav-item nav-link   ${tabSelect == 'future' ? 'active':""}`}
                onClick={()=>setTabSelect('future')}
                data-toggle="tab"
              >
                Futures
              </div>
              <div
                class={`nav-item nav-link   ${tabSelect == 'new' ? 'active':""}`}
                onClick={()=>setTabSelect('new')}
                data-toggle="tab"
              >
                New Listing
              </div>
              <div
                class={`nav-item nav-link  me-4 ${tabSelect == 'all' ? 'active':""}`}
               onClick={()=>setTabSelect('all')}
                data-toggle="tab"
              >
                All
              </div>
            </div>
            </div>
            </div>
                    {/* end */}
                    <div className="mt-3">
                      <div className="col-lg-12 col-md-12 col-sm-12 table-responsive market_table">
                        <table className="table  home_table">
                        <thead>
                    <tr >
                      <th>Trading Pair</th>
                      <th>Last Price</th>
                      <th>24h Change</th>
                      <th>Market Cap</th>
                      <th>4H Trend</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                          <tbody>
                            {loader ? (
                              <tr>
                                <td colSpan="6" style={{ textAlign: "center" }}>
                                  <Loader />
                                </td>
                              </tr>
                            ) : (
                              market_child
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="favourites">favourites</div>
              <div className="tab-pane fade" id="spot">spot</div>
              <div className="tab-pane fade" id="futures">Futures</div>
              <div className="tab-pane fade" id="listing">New Listings</div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
