import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NotificationManager } from "react-notifications";
import { API_URL } from "../../constants";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import axios from "axios";
import { useTranslation } from "react-i18next";
export default function Banner(props) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // Check localStorage for "amexaSubscribed"
    const isSubscribed = localStorage.getItem("amexaSubscribed");

    // If not found or false, open the modal
    if (!isSubscribed) {
      // setIsModalOpen(true);
    } else {
      // setIsModalOpen2(true);
    }
  }, [isModalOpen]);

  const [name, setName] = useState();
  const [lang, setLang] = useState(currentLanguage);
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [tradingExp, setTradingExp] = useState("");
  const [trdMkt, setTrdMkt] = useState("");
  const [file, setFile] = useState(null);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };
  var settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoPlay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Check if file size is greater than 2MB (2 * 1024 * 1024 bytes)
      if (file.size > 2 * 1024 * 1024) {
        // Show toast notification for file size error
        NotificationManager.error(
          "File size exceeds 2 MB. Please select a file smaller than 2 MB."
        );
        return; // Exit function
      }

      setFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result); // Set the base64 image
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDelete = () => {
    setSelectedImage(null); // Clear the selected image
  };

  const handleSubscribe = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    if (!file) {
      // Notify the user if no file is uploaded
      NotificationManager.error("Please upload the file!");
      return; // Exit the function early
    }

    if (!name || !email) {
      // Validate that all required fields are filled
      NotificationManager.error("Please fill in all the required fields!");
      return; // Exit the function early
    }

    // Perform the subscription logic if all fields are valid
    try {
      setLoading(true);
      console.log("Subscription details:", { name, email, phone, file });
      const formData = new FormData();

      // Append fields to the FormData object
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("fileupload", file);
      formData.append("tradingExperience", tradingExp);
      formData.append("tradingMarketing", trdMkt);
      formData.append("lang", currentLanguage);

      // Store the subscription status in localStorage
      axios
        .post(`${API_URL}api/subscriber-contact`, formData, {
          headers: {
            "content-type": "application/json",
            "cache-control": "no-cache",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          console.log(res.data);
          NotificationManager.success("You have successfully subscribed!");
          localStorage.setItem("amexaSubscribed", true);
          setIsModalOpen(false);
          setLoading(false);
        })
        .catch((err) => {
          NotificationManager.error(
            err?.response?.data?.message || "Something went wrong!"
          );
          setLoading(false);
        });

      // Optionally notify the user about the success
    } catch (error) {
      setLoading(false);
      // Handle unexpected errors gracefully
      NotificationManager.error(
        "An error occurred during subscription. Please try again!"
      );
      console.error("Subscription error:", error);
    }
  };

  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  AOS.init({ duration: 1000 });
  const { coins } = useSelector((state) => state.coinDBReducer);
  let coins1 = Object.values(coins);

  const banner_child =
    coins1 &&
    coins1.map((item, index) => {
      let usdtprize = coins1 && coins1.find((item) => item.symbol == "USDT");
      return (
        <>
          {item.symbol == "BTC" ||
          item.symbol == "BNB" ||
          item.symbol == "ETH" ||
          item.symbol == "TRX" ? (
            <div className="col-lg-3" key={index}>
              <div className="card banner_child">
                <div className="card-body">
                  <p className="">
                    {item.symbol}/USDT&nbsp;&nbsp;
                    <span
                      className={
                        item.direction == "up" ? "text-green" : "text-red"
                      }
                    >
                      {item.price_change_percentage_1h}%
                    </span>
                  </p>
                  <h5>
                    $
                    {(item?.current_price / usdtprize?.current_price).toFixed(
                      8
                    )}
                  </h5>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      );
    });
  return (
    <>
      <div className="page-content">
        <div className="home_page_banner">
          <div className="container">
            <div className="row align-items-center mt-5 mt-5">
              <div className="col-md-6 mb-3">
                <div className="banner_left_inner">
                  <h2 className="bannertxt">
                    {t("Gatewayto")}{" "}
                    <span className="text-gradient">{t("DigitalAsset")}</span>,
                    <span className="">{t("HighwaytoSuccess")}</span>
                  </h2>
                  <p className="mb-2 mt-5 bannerSubHeading">
                    {" "}
                    {t("bannerSubheading1")}
                  </p>
                  <p className="bannerSubHeading">{t("bannerSubheading2")}</p>
                  <div className="mt-4">
                    {" "}
                    {!isLoggedIn ? (
                      <>
                        <Link className="btn login_btn px-4" to="/create">
                          {t("SignUpNow")}
                        </Link>
                      </>
                    ) : (
                      <a className="btn btn login_btn px-4 " href="#trade_now">
                        {t("TradeNow")}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about_section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="about_text">
                <div className="about_heading">
                  Fast-track your path to{" "}
                  <span className="text-gradient">market</span> and{" "}
                  <span className="text-gradient">
                    unlock <br className="d-none d-md-block" />
                  </span>{" "}
                  new <span className="text-gradient">revenue potential</span>{" "}
                  with ease
                </div>
                <div className="about_subheading">
                  Vision and Mission of AMEXA
                </div>
                <div className="about_content">
                  To empower traders worldwide with a secure, transparent, and
                  innovative platform that seamlessly integrates crypto, forex,
                  and wallet functionalities. We strive to enhance trading
                  experiences through cutting-edge technology, personalized
                  support, and a commitment to fostering financial growth for
                  all.
                </div>
              </div>
              <div className="about_card_div">
                <div className="row">
                  <div className="col-md-4">
                    <div className="about_card about_card_border_right mb-2">
                      <div className="about_card_heading">
                        Seamless Cryptocurrency Trading
                      </div>
                      <div className="about_card_content">
                        Step into the future of finance with Amexa—a secure,
                        user-friendly platform designed to simplify
                        cryptocurrency transactions and build trust in every
                        trade.
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="about_card about_card_border_right mb-2">
                      <div className="about_card_heading">
                        Blockchain-Powered Solutions
                      </div>
                      <div className="about_card_content">
                        Leverage cutting-edge blockchain technology to enhance
                        your trading experience—ensuring security, transparency,
                        and complete control over your financial journey.
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="about_card mb-2 ">
                      <div className="about_card_heading">
                        Global Forex Connectivity
                      </div>
                      <div className="about_card_content">
                        SSeamlessly access the global forex market with
                        real-time currency conversions and limitless trading
                        opportunities, all integrated into one innovative
                        platform.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="slider_bg">
        <div className="container custom_overflow">
          <div className="currency_section custom_border_gradient">
            <Carousel
              swipeable={false}
              draggable={false}
              showDots={false}
              responsive={responsive}
              pauseOnHover={false}
              ssr={true}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000} // Slows down the autoplay cycle
              keyBoardControl={false}
              transitionDuration={1000} // Smooth and slow transition
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              customTransition="transform 2s linear" // Very slow motion
            >
              <div className="carousel">
                <img width="100%" src="/img/portfolio-transactions1.png" />
              </div>
              <div className="carousel">
                <img width="100%" src="/img/portfolio-transactions2.png" />
              </div>

              <div className="carousel">
                <img width="100%" src="/img/portfolio-transactions4.png" />
              </div>
              <div className="carousel">
                <img width="100%" src="/img/portfolio-transactions5.png" />
              </div>
              <div className="carousel">
                <img width="100%" src="/img/portfolio-transactions3.png" />
              </div>
              <div className="carousel">
                <img
                  style={{ marginLeft: "50px" }}
                  width="100%"
                  src="/img/portfolio-transactions6.png"
                />
              </div>
            </Carousel>
          </div>
          {/* <div className="custom_border_gradient mt-0 pt-0"></div> */}
        </div>
      </div>

      <div class="modal fade" tabindex="-1" id="paymentmodal">
        <div class="modal-dialog  modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Select Payment Method</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <p>Debit and Credit Cards</p>
            </div>
          </div>
        </div>
      </div>

      {/* register modal */}
      <div>
        {/* Div to trigger modal */}

        {/* Modal */}
        <div
          className={`modal fade bg-blur ${isModalOpen ? "show d-block" : ""}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="paymentmodal1"
          aria-hidden={!isModalOpen}
          style={{ display: isModalOpen ? "block" : "none" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content first_registration_section p-2">
              <div className="modal-body text-white">
                {/* <div className="first_registration_bg">
                  <div className="text-gradient first_rg_heading">
                    Be Part of Something Big,
                  </div>
                  <div className="first_rg_subheading">
                    Sign up now and stay ahead of the curve! Join our pre-launch
                    community to
                    <br className="d-none d-md-block" /> receive exclusive updates, early access, and special
                    perks
                  </div>
                </div> */}
                <div className="first_registration_heading_section">
                  <div>
                    <img
                      style={{ width: "80px", marginBottom: "18px" }}
                      src="/img/logo.png"
                    />
                  </div>
                  <div className="first_registration_heading">
                    {t("subscribeHeading")}
                  </div>
                  <div className="first_registration_subheading">
                    {t("subscribeSubheading")}
                  </div>
                </div>

                <form className="form px-3" onSubmit={handleSubscribe}>
                  <div className="form-group mb-4">
                    {/* <label
                      htmlFor="user_password"
                      className="fw-bold mb-2 text-gradient"
                    >
                      Name
                    </label> */}
                    <input
                      type="text"
                      name="name"
                      className="form-control register_input"
                      value={name}
                      autoComplete="off"
                      id="name"
                      onChange={(e) => setName(e.target.value)}
                      placeholder={t("name")}
                      required
                    />
                  </div>
                  <div className="form-group mb-4">
                    {/* <label
                      htmlFor="user_password"
                      className="fw-bold mb-2 text-gradient"
                    >
                      Email
                    </label> */}
                    <input
                      type="email"
                      name="user_email"
                      className="form-control register_input"
                      value={email}
                      autoComplete="off"
                      id="user_email"
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder={t("email")}
                      required
                    />
                  </div>

                  <div className="row mb-5">
                    <div className="col-12">
                      <div className="form-group mb-4">
                        <select
                          className="form-control form-select register_input"
                          aria-label="Default select example"
                          onChange={(e) => setTradingExp(e.target.value)}
                          required
                        >
                          <option value="" selected>
                            {t("selectTradingExp")}
                          </option>
                          <option value="beginner">{t("beginner")}</option>
                          <option value="intermediate">
                            {t("intermediate")}
                          </option>
                          <option value="professional">
                            {t("professional")}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group mb-4">
                        <select
                          className="form-control form-select register_input"
                          aria-label="Default select example"
                          onChange={(e) => setTrdMkt(e.target.value)}
                          required
                        >
                          <option value="" selected>
                            {t("preferredTradingMkt")}
                          </option>
                          <option value="forexMarket">{t("ForexMkt")}</option>
                          <option value="cryptoMarket">{t("cryptoMkt")}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`form-group ${
                      i18n.language === "ar" ? "rtl" : ""
                    }`}
                    dir={i18n.language === "ar" ? "rtl" : "ltr"}
                  >
                    <label
                      htmlFor="phone"
                      className={`labels mb-2 ${
                        i18n.language === "ar" ? "text-right" : "text-white"
                      }`}
                    >
                      {t("mobileLable")}
                    </label>
                    <PhoneInput
                      country="in"
                      enableSearch={true}
                      specialLabel=""
                      className={`phone-input ${
                        i18n.language === "ar" ? "rtl" : ""
                      }`}
                      placeholder={t("mobileNumber")}
                      id="user_phone"
                      value={phone}
                      onChange={(phone) => setPhone("+" + phone)}
                      dropdownClass="phone_input_dropdown text-white"
                      inputStyle={{
                        width: "100%",
                        height: "20px",
                        textAlign: i18n.language === "ar" ? "right" : "left",
                      }}
                      isValid={(value, country) =>
                        value.match(/12345/)
                          ? // @ts-ignore
                            `Invalid value: ${value}, ${country?.name}`
                          : !value.match(/1234/)
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="phone" className="labels text-white mb-2">
                      {t("uploadImgLabel")}
                    </label>
                    <div className="file-upload-container">
                      {!selectedImage ? (
                        <>
                          {/* Initial upload label */}
                          <label
                            className="file-upload-label"
                            htmlFor="fileInput"
                          >
                            <div className="upload-icon">
                              <img
                                style={{ width: "38px" }}
                                src="/img/upload_icon.png"
                                alt="Upload Icon"
                              />
                            </div>
                            <p className="text-white mt-2">{t("proveImg")}</p>
                          </label>
                          <input
                            type="file"
                            id="fileInput"
                            className="file-input"
                            onChange={handleFileChange}
                          />
                        </>
                      ) : (
                        <div className="image-preview">
                          <img
                            src={selectedImage}
                            alt="Preview"
                            className="preview-image"
                          />
                          <div className="preview-buttons mb-4">
                            <div>
                              <button
                                className="btn px-3 py-1 btn-danger btn-sm"
                                onClick={handleDelete}
                              >
                                {t("delete")}
                              </button>
                            </div>
                            <div>
                              <label
                                style={{ height: "30px" }}
                                className="btn py-2 px-3 btn-primary login_btn btn-sm"
                              >
                                {t("update")}
                                <input
                                  type="file"
                                  id="updateFileInput"
                                  className="file-input"
                                  onChange={handleFileChange}
                                  style={{ display: "none" }}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="my-3 d-flex justify-content-end gap-3">
                    <a
                      href={
                        currentLanguage == "en"
                          ? "https://www.facebook.com/profile.php?id=61568402387564"
                          : "https://www.facebook.com/profile.php?id=61569317962879 "
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="register_social_icon"
                        src="/img/facebook.png"
                      />
                    </a>
                    <a
                      href={
                        currentLanguage == "en"
                          ? "https://www.instagram.com/amexa_en/?igsh=MTZ0ejhic2ZwbjJ5YQ%3D%3D"
                          : "https://www.instagram.com/amexa_ar/ "
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="register_social_icon"
                        src="/img/insta.png"
                      />
                    </a>
                    <a
                      href={
                        currentLanguage == "en"
                          ? "https://x.com/Amexa418481"
                          : "https://x.com/Amexacorpo "
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="register_social_icon"
                        src="/img/twitter.png"
                      />
                    </a>
                  </div>
                  <div className="d-flex justify-content-end mt-4">
                    {loading ? (
                      <div
                        style={{ width: "100%", fontFamily: "Instrument Sans" }}
                        className="login_btn px-5 text-center"
                      >
                        Processing...
                      </div>
                    ) : (
                      <button
                        style={{ width: "100%", fontFamily: "Instrument Sans" }}
                        type="submit"
                        className="login_btn px-5"
                      >
                        {t("submit")}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Backdrop */}
        {isModalOpen && (
          <div className="modal-backdrop fade show" onClick={closeModal}></div>
        )}
      </div>
      {/* end  */}

      {/* register modal */}
      <div>
        {/* Div to trigger modal */}

        {/* Modal */}
        <div
          className={`modal  fade no-scroll bg-blur ${
            isModalOpen2 ? "show d-block" : ""
          }`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="paymentmodal2"
          aria-hidden={!isModalOpen2}
          style={{ display: isModalOpen2 ? "block" : "none" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content first_registration_section comming-soon-bg p-2">
              <div className="modal-body text-white">
                <div className="first_registration_heading_section">
                  <div className="my-2">
                    <img
                      style={{ width: "80px", marginBottom: "18px" }}
                      src="/img/logo.png"
                    />
                  </div>
                  <div className="coming_registration_heading">
                    {t("commingSoonHeading")}
                  </div>
                  <div className="coming_registration_subheading mt-4">
                    {t("commingSoonSubheading")}
                  </div>
                  <div className="coming_text mt-5 py-3">{t("ComingSoon")}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Backdrop */}
        {isModalOpen2 && (
          <div className="modal-backdrop fade show" onClick={closeModal2}></div>
        )}
      </div>
      {/* end  */}
    </>
  );
}
