import React, { useState } from "react";
import Header from "../../HomeComp/Header";
import Footer from "../../HomeComp/Footer";
import { Field, Form, Formik } from "formik";
import { NotificationManager } from "react-notifications";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { N_addPaymentMethod } from "../../redux/helpers/api_functions_new";
import { useHistory } from "react-router-dom";

const BankTransfer = () => {
  const history = useHistory();
  const { user, profile } = useSelector((state) => state.AuthReducer);

  const Schema = Yup.object().shape({
    name: Yup.string().required("Please Enter Account Holder Name"),
    bank_name: Yup.string().required("Please Enter Bank Name"),
    account_number: Yup.string().required("Please Enter Account Number"),
    confirm_account_number: Yup.string()
      .oneOf([Yup.ref("account_number"), null], "Account Number Must Match")
      .required("Please Enter Account Number"),
    ifsc: Yup.string().required("Please Enter IFSC Code"),
    account_type: Yup.string().required("Please Enter Account Type"),
  });
  const [data, setData] = useState({
    user_id: user?.user_id,
    name: "",
    bank_name: "",
    account_number: "",
    confirm_account_number: "",
    payment_method_type: "bank",
    ifsc: "",
    account_type: "",
    email: profile?.email,
    mobile_number: profile?.mobile_number,
  });
  const onSubmit = async (values, onSubmitProps) => {
    console.log(values, "values");
    N_addPaymentMethod(values).then((res) => {
      if (res.status == 200) {
        NotificationManager.success(res.message);
        history.push("/payment-method");
      } else {
        NotificationManager.error(res.message);
      }
    });
  };
  return (
    <>
      <Header />
      <div className="container" style={{ marginTop: "100px" }}>
        <div className="d-flex justify-content-center align-items-center">
          <section className="col-md-8">
            <h4>Add Bank Account</h4>
            <div className="container border rounded-4 p-4 d-flex flex-column gap-3 mt-3">
              <section
                className=" rounded-2 p-4"
                style={{ background: "#2E2A1E" }}
              >
                <p className="text-white">
                  Tips: The added payment method will be shown to the buyer
                  during the transaction to accept fiat transfers. Please ensure
                  that the information is correct, real, and matches your KYC
                  information on Binance.
                </p>
              </section>
              <Formik
                initialValues={{ ...data }}
                enableReinitialize
                validationSchema={Schema}
                onSubmit={onSubmit}
              >
                {({ errors, touched, values }) => (
                  <Form>
                    <div className="row mb-4">
                      <div className="col-6">
                        <label htmlFor="host" className="mb-1">
                          Account Holder Name
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          name="name"
                          id="name"
                          placeholder="Enter Account Holder Name"
                          className="form-control"
                          autoComplete="off"
                        />
                        {errors.name && touched.name ? (
                          <small className="text-danger">{errors.name}</small>
                        ) : null}
                      </div>
                      <div className="col-6">
                        <label htmlFor="host" className="mb-1">
                          Bank Name
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          name="bank_name"
                          id="bank_name"
                          placeholder="Enter Bank Name"
                          className="form-control"
                          autoComplete="off"
                        />
                        {errors.bank_name && touched.bank_name ? (
                          <small className="text-danger">
                            {errors.bank_name}
                          </small>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-6">
                        <label htmlFor="host" className="mb-1">
                          Account Number
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          name="account_number"
                          id="account_number"
                          placeholder="Enter Account Number"
                          className="form-control"
                          autoComplete="off"
                        />
                        {errors.account_number && touched.account_number ? (
                          <small className="text-danger">
                            {errors.account_number}
                          </small>
                        ) : null}
                      </div>
                      <div className="col-6">
                        <label htmlFor="host" className="mb-1">
                          Confirm Account Number
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          name="confirm_account_number"
                          id="confirm_account_number"
                          placeholder="Confirm Account Number"
                          className="form-control"
                          autoComplete="off"
                        />
                        {errors.confirm_account_number &&
                        touched.confirm_account_number ? (
                          <small className="text-danger">
                            {errors.confirm_account_number}
                          </small>
                        ) : null}
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-6">
                        <label htmlFor="host" className="mb-1">
                          IFSC Code
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          name="ifsc"
                          id="ifsc"
                          placeholder="Enter IFSC Code"
                          className="form-control"
                          autoComplete="off"
                        />
                        {errors.ifsc && touched.ifsc ? (
                          <small className="text-danger">{errors.ifsc}</small>
                        ) : null}
                      </div>
                      <div className="col-6">
                        <label htmlFor="host" className="mb-1">
                          Account Type
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          as="select"
                          name="account_type"
                          id="account_type"
                          placeholder="Select Account Type"
                          className="form-control"
                          autoComplete="off"
                        >
                          <option value="">--Select--</option>
                          <option value="saving">Saving</option>
                          <option value="current">Current</option>
                        </Field>
                        {errors.account_type && touched.account_type ? (
                          <small className="text-danger">
                            {errors.account_type}
                          </small>
                        ) : null}
                      </div>
                    </div>

                    <div className="mb-4 text-center">
                      <button type="submit" className="btn login_btn py-1 px-4">
                        Update
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BankTransfer;
