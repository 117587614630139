import $ from "jquery";
// import { browserName } from "react-device-detect";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import {
  API_URL,
  ORDER_API_URL,
  WEBSITE_URL,
  ADMIN_URL,
} from "../../../constants";
// const url = "http://localhost:5009/api";
// const url = "https://api.AMEXA.exchange/api";
// export const WebsiteURL =  'https://AMEXA.exchange' ;
// export const adminurl = "https://adminapi.AMEXA.exchange"
// const orderurl = "https://orderbook.AMEXA.exchange/api";

const url = `${API_URL}api`;
export const WebsiteURL = `${WEBSITE_URL}`;
export const adminurl = `${ADMIN_URL}`;
const orderurl = `${ORDER_API_URL}api`;
// const signal = new AbortController();

export function N_userRegister(email, mobile_number, password, referral) {
  return fetch(`${url}/register-user`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      email: email,
      mobile_number: mobile_number,
      password: password,
      parent_ref_code: referral,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_verifyOTP(otp, session_id) {
  return fetch(`${url}/varifie/email`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ otp: otp, user_id: session_id }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_userLogin(email, mobile, password, otp) {
  // console.log("N_login: ", email, password);
  return fetch(`${url}/login-new`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      email: email,
      password: password,
      mobile: mobile,
      otp: otp,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_resendOTP(email) {
  return fetch(`${url}/resend-otp`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ email: email }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_forgetPassward(email) {
  return fetch(`${url}/forget-password`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      email: email,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_verifyForgetOTP(otp, email) {
  return fetch(`${url}/varifie/forget-password`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ otp: otp, email: email }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}
export function N_updateName(user_id, name) {
  return fetch(`${url}/update-name`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id,
      name,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_profileData(
  user_id,
  type,
  id_no,
  first_name,
  last_name,
  email,
  country
) {
  return fetch(`${url}/kyc/update-profile`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id,
      type,
      id_no,
      first_name,
      last_name,
      email,
      country,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_sendUpdateEmail(user_id, email, action) {
  return fetch(`${url}/send-update-email`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ user_id, email, action }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_verifyUpdateEmail(user_id, email, otp, action) {
  return fetch(`${url}/verify-update-email`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ user_id, email, otp, action }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}
export function N_updatePassword(user_id, password, confirm_password) {
  return fetch(`${url}/set-password`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      access_token: user_id,
      confirm_password: confirm_password,
      password: password,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_freezeAccount(user_id, action) {
  return fetch(`${url}/freeze-acount`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ user_id, action }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_resetPassword(
  user_id,
  last_password,
  password,
  confirm_password
) {
  return fetch(`${url}/reset-password`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id,
      last_password,
      password,
      confirm_password,
    }),
  }).then((d) => d.json());
}
export function N_getVHL(symbol) {
  return fetch(`${orderurl}/chart/getVHL`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ symbol: symbol }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}
export function N_RemoteStatus(currency_type, compare_currency) {
  return fetch(`${url}/get-remote-status`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ currency_type, compare_currency }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}
export function N_RemoteStatusAll() {
  return fetch(`${url}/get-all-remote-status`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .catch((e) => e);
}
export function N_createBuyOffer(
  raw_price,
  volume,
  currency_type,
  compare_currency,
  user_id,
  cprice,
  type = "buy"
) {
  return fetch(`${orderurl}/buy-order1`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
      currency_type: currency_type,
      volume: volume,
      compare_currency: compare_currency,
      raw_price: raw_price,
      type: type,
      cprice: cprice,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_createp2pSellOrder(
  raw_price,
  volume,
  currency_type,
  compare_currency,
  user_id
) {
  return fetch(`${url}/sell-p2p-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
      currency_type: currency_type,
      volume: volume,
      compare_currency: compare_currency,
      raw_price: raw_price,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}
export function N_p2pSellOrder(user_id, type, coin) {
  return fetch(`${url}/p2p-order-list`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
      type: type,
      coin: coin,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_p2pInitiateOrder(user_id, order_token) {
  return fetch(`${url}/initiate-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
      order_token: order_token,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_p2pOrderAction(user_id, order_token, type) {
  return fetch(`${url}/p2p-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
      order_token: order_token,
      type: type,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_p2pCancelOrder(user_id, order_token) {
  return fetch(`${url}/p2p-cancel-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
      order_token: order_token,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_createSellOffer(
  raw_price,
  volume,
  currency_type,
  compare_currency,
  user_id,
  cprice,
  type = "sell"
) {
  return fetch(`${orderurl}/sell-order1`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
      currency_type: currency_type,
      volume: volume,
      compare_currency: compare_currency,
      raw_price: raw_price,
      type: type,
      cprice: cprice,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}
export function N_executeOrder(order_id, user_id, type) {
  return fetch(`${orderurl}/execute-orders`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      order_id: order_id,
      user_id: user_id,
      type: type,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_userInfoSubmit(body) {
  return fetch(`${url}/kyc/set-personal-info`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      kyc_type: body.type_of_kyc,
      first_name: body.fname,
      middle_name: body.mname,
      last_name: body.lname,
      date_of_birth: new Date(body.dob).getTime(),
      address: body.address,
      country: body.country,
      state: body.state,
      city: body.city,
      pincode: body.pincode,
      user_id: body.user_id,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}
export function N_userEditSubmit(body) {
  return fetch(`${url}/updateprofile`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(body),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_setKyc(event, user_id) {
  const formControl = event.target?.parentElement;
  let body = {};
  try {
    formControl.querySelector(".spinner-border").style.display = "block";
    const typeInput = formControl.querySelector("input");
    body.user_id = user_id;
    if (typeInput.type === "text") {
      const val = formControl.querySelector("input").value;
      if (typeInput.id === "user_adhar") {
        body.data = {
          value: val,
          type: "Adhar",
        };
        body.title = "docn";
      } else if (typeInput.id === "user_dl") {
        body.data = {
          value: val,
          type: "Driving",
        };
        body.title = "docn";
      } else if (typeInput.id === "user_passport") {
        body.data = {
          value: val,
          type: "Passport",
        };
        body.title = "docn";
      } else if (typeInput.id === "user_pancard") {
        body.data = val;
        body.title = "pann";
      }
    }
  } catch (e) {
    console.log("setkyc: ", e);
  }

  try {
    fetch(`${url}/kyc/update-documents`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "cache-control": "no-cache",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    })
      .then((d) => d.json())
      .then((res) => {
        if (res && res.status === 200) {
          // console.log("success: ", res);
          $("#" + event.target.id).text("Resend");
        }
        if (res && res.status === 400) {
          console.log("error: ", res);
          NotificationManager.error(res.message);
          $("#" + event.target.id).text("Resend");
        }
        formControl.querySelector(".spinner-border").style.display = "none";
      })
      .catch((e) => {
        console.log(e);
      });
  } catch (error) {
    console.error("An unexpected error happened:", error);
  }
}

export function N_uploadID(event, file, title, user_id) {
  event.preventDefault();
  const formData = new FormData();
  // The third parameter is required for server
  formData.append("file", file, file.name);
  formData.append("title", title);
  formData.append("user_id", user_id);
  formData.append("data", title);
  axios
    .post(`${url}/upload-kyc`, formData)
    .then((res) => {
      if (res && res.status === 200) {
        console.log("res", res);
        NotificationManager.success(res.data.message);
        setTimeout(() => {
          document.location.reload();
        }, 1000);
      }
      if (res && res.status === 400) {
        NotificationManager.error(res.data.message);
      }
    })
    .catch((e) => {
      console.log(e);
    });
}

export function N_test(event, file, title, user_id) {
  event.preventDefault();
  const formControl = event.target?.parentElement;
  formControl.querySelector(".spinner-border").style.display = "block";
  const formData = new FormData();
  console.log(file);
  // The third parameter is required for server
  formData.append("file", file, file.name);
  formData.append("title", title);
  formData.append("user_id", user_id);
  formData.append("data", title);
  console.log("chalform0: ", formData);
  axios
    .post(`${url}/kyc/update-documents`, formData)
    .then((res) => {
      if (res && res.status === 200) {
        console.log("chalform: ", formData);
        $("#" + event.target.id).text("Resend");
      }
      if (res && res.status === 400) {
        NotificationManager.error(res.msg);
        $("#" + event.target.id).text("Resend");
      }
      formControl.querySelector(".spinner-border").style.display = "none";
    })
    .catch((e) => {
      console.log(e);
    });
}

export function N_ScreenShot(event, file, price, user_id, req_no) {
  event.preventDefault();
  const formControl = event.target?.parentElement;
  const formData = new FormData();
  // The third parameter is required for server
  if (file && price && user_id) {
    formControl.querySelector(".spinner-border").style.display = "block";
    formData.append("file", file, file.name);
    formData.append("price", price);
    formData.append("req_no", req_no);
    formData.append("user_id", user_id);

    axios
      .post(`${url}/upload-screenshot`, formData)
      .then((res) => {
        const data = res.data;
        if (res.status === 200 && data.status === 200) {
          NotificationManager.success(data.message);
        } else {
          NotificationManager.error(data.message);
        }
        formControl.querySelector(".spinner-border").style.display = "none";
      })
      .catch((e) => {
        NotificationManager.error("Something went Wrong!!");
      });
  } else {
    NotificationManager.error("Provide all Data");
  }
}

export function N_Screenp2pShot(
  event,
  file,
  transection_id,
  order_token,
  user_id
) {
  event.preventDefault();
  const formControl = event.target?.parentElement;
  const formData = new FormData();
  // The third parameter is required for server
  if (file && transection_id && order_token && user_id) {
    formControl.querySelector(".spinner-border").style.display = "block";
    formData.append("file", file, file.name);
    formData.append("transection_id", transection_id);
    formData.append("order_token", order_token);
    formData.append("user_id", user_id);

    axios
      .post(`${url}/upload-payment-screenshot`, formData)
      .then((res) => {
        const data = res.data;
        if (res.status === 200 && data.status === 200) {
          NotificationManager.success(data.message);
        } else {
          NotificationManager.error(data.message);
        }
        formControl.querySelector(".spinner-border").style.display = "none";
      })
      .catch((e) => {
        NotificationManager.error("Something went Wrong!!");
      });
  } else {
    NotificationManager.error("Provide all Data");
  }
}

// export function N_uploadSelfie(event, user_id) {
//   $("#" + event.target.id).hide();
//   $(".cancelbtn").hide();
//   const formControl = event.target?.parentElement;
//   formControl.querySelector(".spinner-border").style.display = "block";
//   var body = {};
//   body.user_id = user_id;
//   const val = formControl.querySelector("img").src;
//   body.data = val;
//   body.title = "docs";
//   console.log(body);
//   try {
//     fetch(`${url}/kyc/update-documents`, {
//       method: "POST",
//       headers: {
//         "content-type": "application/json",
//         "cache-control": "no-cache",
//         "Access-Control-Allow-Origin": "*",
//       },
//       body: JSON.stringify(body),
//     })
//       .then((d) => d.json())
//       .then((res) => {
//         if (res && res.status === 200) {
//           $(".cancelbtn").show();
//           $(".cancelbtn").text("Reclick");
//           formControl.querySelector(".spinner-border").style.display = "none";
//         }
//       })
//       .catch((e) => {
//         console.log(e);
//       });
//   } catch (error) {
//     console.error("An unexpected error happened:", error);
//   }
// }

export function N_checkKYCStatus(user_id) {
  return fetch(`${url}/kyc/check-status`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getBlockchainData(blockchain, user_id, symbol) {
  return fetch(`${url}/get-blockchain-data`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      blockchain,
      user_id,
      symbol,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getBlockchain() {
  return fetch(`${url}/get-blockchain`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getAllBlockchain(symbol) {
  return fetch(`${url}/get-all-blockchains`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      symbol: symbol || "",
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}
export function N_GetAllSymbol() {
  return fetch(`${url}/getallsymbol`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_ischeckKycSubmited(user_id, data) {
  return fetch(`${url}/kyc/update-documents`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
      title: "kycstatus",
      data: data,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_get_wallet(user_id) {
  return fetch(`${url}/get-wallets-new`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ user_id: user_id }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_transferAmonut(type, wallet_type, user_id, volume, action) {
  return fetch(`${url}/transfer-fund`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ type, wallet_type, user_id, volume, action }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_createAddress(user_id, symbol) {
  return fetch(`${url}/create-wallets-user`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
      symbol: symbol,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_getSupportedCurrency(user_id) {
  return fetch(`${url}/getpairedCurrency`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ user_id: user_id }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_tradeOrder(user_id, action) {
  return fetch(`${url}/order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ user_id, action }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_spotOrder(
  user_id,
  pair,
  side,
  type,
  action,
  startDate,
  endDate
) {
  return fetch(`${url}/order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id,
      pair,
      side,
      type,
      action,
      startDate,
      endDate,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_getUserBankDetails(user_id) {
  return fetch(`${url}/banking/get-banking-status`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_addBinficiary(
  account_number,
  confirm_account_number,
  bank_name,
  name,
  ifsc_code,
  account_type,
  user_id
) {
  return fetch(`${url}/banking/set-banking-info`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      bank_name: bank_name,
      name: name,
      account_type: account_type,
      account_number: account_number,
      confirm_account_number: confirm_account_number,
      ifsc_code: ifsc_code,
      user_id: user_id,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_OrdersHistory(user_id) {
  return fetch(`${url}/order-history`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_pendingOrder(user_id) {
  return fetch(`${url}/pending-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_completeOrder(user_id) {
  return fetch(`${url}/complete-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_setGoogleAuth(user_id) {
  return fetch(`${url}/set-auth-google`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_setGoogleAuthOtp(user_id, state) {
  return fetch(`${url}/set-auth-google-otp`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
      status: state,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_setGoogleAuthOTP(user_id, state, otp, action) {
  return fetch(`${url}/set-auth-google-otp`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
      status: state,
      otp,
      action,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_get_settings(user_id) {
  return fetch(`${url}/get-auth-google-setting`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ user_id: user_id }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_getUserProfile(user_id) {
  return fetch(`${url}/user/get-profile-info`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getProfile(user_id) {
  return fetch(`${url}/get-profile-info`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_sendOTPMobile(user_id, mobile_number) {
  return fetch(`${url}/send-mobile-varification-otp`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
      mobile_no: mobile_number,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_sendOTPMobileEmail(email, mobile_number) {
  return fetch(`${url}/send-mobile-varification-otp-email`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      email: email,
      mobile_no: mobile_number,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_sendOTP(email, mobile, action) {
  return fetch(`${url}/sendOTP`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ email, mobile, action }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_verifyOTPNew(email, mobile, otp) {
  return fetch(`${url}/verifyOTP`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ email, mobile, otp }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_verifyOTPMobile(user_id, otp) {
  return fetch(`${url}/varifie/mobile`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
      otp: otp,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}
export function N_verifyOTPMobileLogin(email, otp) {
  return fetch(`${url}/varifie/mobile-login`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      email: email,
      otp: otp,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_checkMobileStatus(user_id) {
  return fetch(`${url}/check_user_status`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getGoogleAuth(email, mobile, password) {
  return fetch(`${url}/get-auth-google`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      email: email,
      mobile: mobile,
      password: password,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_getRefferalData(user_id) {
  return fetch(`${url}/user/get-referals`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_cancleOrderById(user_id, order_id) {
  return fetch(`${url}/cancle-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
      order_id: order_id,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_DepositUpdate(user_id) {
  return fetch(`${url}/update-wallet`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
    }),
  })
    .then((d) => d)
    .catch((e) => e);
}

export function N_WalletDepositUpdate(user_id) {
  return fetch(`${url}/update-wallet-deposit`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
    }),
  })
    .then((d) => d)
    .catch((e) => e);
}

export function N_crypto_withdraw(
  user_id,
  symbol,
  address,
  to_address,
  volume,
  remark
) {
  return fetch(`${url}/get-withdraw`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
      fromAddress: address,
      symbol: symbol,
      toAddress: to_address,
      volume: volume,
      remark: remark,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_crypto_withdraw_New(
  user_id,
  symbol,
  to_address,
  volume,
  network
) {
  return fetch(`${url}/get-withdraw`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
      symbol: symbol,
      toAddress: to_address,
      volume: volume,
      network: network,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_crypto_withdraw_Otp(
  user_id,
  otp,
  transection_id,
  wallettype
) {
  return fetch(
    `${url}/varifie/mobile-Withdraw
  `,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "cache-control": "no-cache",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        user_id: user_id,
        otp: otp,
        transection_id: transection_id,
        symbol: wallettype,
      }),
    }
  )
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_crypto_withdraw_Otp_Email(
  user_id,
  otp,
  transection_id,
  wallettype
) {
  return fetch(
    `${url}/varifie/email-Withdraw
  `,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "cache-control": "no-cache",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        user_id: user_id,
        otp: otp,
        transection_id: transection_id,
        symbol: wallettype,
      }),
    }
  )
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_inr_withdraw(user_id, volume, symbol, remark) {
  return fetch(`${url}/banking/inr_withdrawal`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
      volume: volume,
      symbol: symbol,
      remark: remark,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_TransferP2P(user_id, volume, wallet_type, type) {
  return fetch(`${url}/update-p2p-fund`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id,
      volume,
      wallet_type,
      type,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_withdraw_coin(transection_id) {
  return fetch(`${url}/success-withdrawal`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      transection_id: transection_id,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_transectionHistory(user_id) {
  return fetch(`${url}/transection_history`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}
export function N_depositHistory(data) {
  return fetch(`${url}/deposit_history`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(data),
  })
    .then((d) => d.json())
    .catch((e) => e);
}
export function N_withdrawHistory(data) {
  return fetch(`${url}/withdraw_history`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(data),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_withdraw_inr(transection_id) {
  return fetch(`${url}/success-inr-withdrawal`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      transection_id: transection_id,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getCountry(user_id) {
  return fetch(`${url}/kyc/get-country`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ action: "country", user_id: user_id }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_getState(country, user_id) {
  return fetch(`${url}/kyc/get-country`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      action: "state",
      country_name: country,
      user_id: user_id,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_getCity(country, state, user_id) {
  return fetch(`${url}/kyc/get-country`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      action: "city",
      country_name: country,
      state_name: state,
      user_id: user_id,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_getBank(user_id) {
  return fetch(`${url}/banking/get-bank`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      user_id: user_id,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getWebsiteData() {
  return fetch(`${url}/get-website-data`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_setActivityReport(user_id) {
  return fetch(`${url}/activity-log`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      action: "set_report",
      user_id: user_id,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}
export function N_getActivityReport(user_id) {
  return fetch(`${url}/activity-log`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      action: "get_report",
      user_id: user_id,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getNotification(user_id) {
  return fetch(`${url}/notification`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      action: "get",
      user_id: user_id,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function socialLogin(access_token) {
  return fetch(`${url}/login-social`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      access_token: access_token,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_panVerify(pan_no, user_id) {
  return fetch(`${url}/kyc/verify-pan`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      pan_no: pan_no,
      user_id: user_id,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_sendAADHAROTPMobile(aadhar_no, user_id) {
  return fetch(`${url}/kyc/validate-aadhar`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      aadhar_no: aadhar_no,
      user_id: user_id,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_verifyAADHAROTPMobile(client_id, mobile_no, otp, user_id) {
  return fetch(`${url}/kyc/verify-adhar`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      otp: otp,
      mobile_no: mobile_no,
      client_id: client_id,
      user_id: user_id,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}
export function getAPICall(urlslug) {
  const urlaction = url + urlslug;
  return axios.get(urlaction);
}
export function postAPICall(urlslug, fomdata, order) {
  let gurl = order ? orderurl : url;
  const urlaction = gurl + "/" + urlslug;
  return axios.post(urlaction, fomdata);
}
export function N_addPaymentMethod(values) {
  return fetch(`${url}/addpaymentmethods`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(values),
  })
    .then((res) => res.json())
    .catch((e) => e);
}
export function N_getPaymentMethods() {
  return fetch(`${url}/getpaymentmethods`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((d) => d.json())
    .catch((e) => e);
}
