import React, { useState } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";

export default function StartTrade() {
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  AOS.init({ duration: 1000 });
  const [showindex, setShowindex] = useState(null);

  // Dummy data for liveData
  const liveData = [
    {
      _id: "1",
      question: "what is a cryptocurrency exchange?",
      answer:
        "A cryptocurrency exchange is an online platform where you can buy, sell, or trade digital currencies such as Bitcoin or Ethereum. Think of it like a marketplace for cryptocurrencies: you deposit money (traditional or crypto), then use it to purchase or swap different digital assets at prices set by the market.",
    },
    {
      _id: "2",
      question: "how to buy bitcoin and other cryptocurrency on amexa?",
      answer:
        "To buy Bitcoin or other cryptocurrencies on Amexa, you first need to create an account and complete any required verification steps. Next, deposit funds using your preferred payment method, such as a bank transfer or credit card. Once your account is funded, choose the cryptocurrency you want to buy, enter the desired amount, and confirm the purchase. Your newly acquired digital assets will then appear in your Amexa wallet, ready for you to hold or trade as you wish.",
    },
    {
      _id: "3",
      question: "how to track cryptocurrency currency?",
      answer:
        "You can easily track your cryptocurrencies on Amexa, where real-time price updates, interactive charts, and market data are all in one place. Simply log in to your account to view current prices, set up customized watchlists, and enable alerts for specific market moves. With Amexa’s comprehensive tracking tools, you’ll stay informed about your crypto holdings and market trends in real time.",
    },
    {
      _id: "4",
      question: "how to trade cryptocurrency in amexa?",
      answer:
        "On Amexa, trading cryptocurrencies is straightforward. After creating and funding your account, navigate to the trading section to choose which digital asset you’d like to trade. You can place market or limit orders depending on your strategy, monitor live market data for up-to-date prices, and use the platform’s charting tools to track trends. With Amexa’s intuitive interface and real-time analytics, you can manage and execute your trades efficiently in one place.",
    },
  ];
  return (
    <>
    <div className="faq_section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="para_heading text-gradient d-flex justify-content-between align-items-center">
            <div>
            Frequently Asked Questions
              </div>
              <div className="text-end d-none d-md-block">
                <img style={{width:'40%'}} src="/img/object.png" />
              </div>
            </div>
              <div className="mt-5">
              {liveData?.map((item, i) => (
                <>
                  <div
                    className="accordion"
                    id="accordionExample"
                    key={item._id}
                  >
                    <div className={`accordionitem accordion-item`}>
                      <h2 className="accordion-header" id="headingOne2">
                        <button
                          className={`accordionbutton accordion-button
                              ${i === showindex ? "" : "collapsed"}
                              `}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseOne2${i}`}
                          aria-expanded="true"
                          aria-controls="collapseOne2"
                          onClick={() => setShowindex(i)}
                        >
                          {item.question}
                        </button>
                      </h2>
                      <div
                        id={`collapseOne2${i}`}
                        className={`accordion-collapse collapse ${
                          i === showindex ? "show" : null
                        }`}
                        aria-labelledby="headingOne2"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className={`accordionbody accordion-body`}
                        >
                          <p className='p'>{item.answer}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
              </div>
          </div>

        </div>

      </div>

    </div>
  
    </>
  );
}
