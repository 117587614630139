import React from "react";

const AllReferrals = () => {
  return (
    <>
      <div className="mt-5">
        <h1>Referrals</h1>
        <div className="d-flex flex-column gap-4">
          <div className="refer-data">
            <section>
              <p>Today</p>
              <p className="fs-4">0</p>
            </section>
            <section>
              <p>Yesterday</p>
              <p className="fs-4"> 0 </p>
            </section>
            <section>
              <p>Last 30D</p>
              <p className="fs-4">0 </p>
            </section>
            <section>
              <p>Last 30D </p>
              <p className="fs-4"> 0 </p>
            </section>
          </div>
          <div className="refer-data">
            <section>
              <p>Today</p>
              <p className="fs-4">0</p>
            </section>
            <section>
              <p>Yesterday</p>
              <p className="fs-4"> 0 </p>
            </section>
            <section>
              <p>Last 30D</p>
              <p className="fs-4">0 </p>
            </section>
            <section>
              <p>Last 30D </p>
              <p className="fs-4">0 </p>
            </section>
          </div>

          <h1>Referee List</h1>
          <p className="fs-5">
            Direct and indirect referees in the last 30 days.
          </p>
          <div className="refer-data">
            <table className="refer-data-table">
              <thead className="">
                <tr>
                  <th>Refree Account</th>
                  <th>Referral Time</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="fs-4">Sanamdeep Kajla</td>
                  <td className="fs-4">21-90-2024</td>
                </tr>
                <tr>
                  <td className="fs-4">Sanamdeep Kajla</td>
                  <td className="fs-4">21-90-2024</td>
                </tr>
                <tr>
                  <td className="fs-4">Sanamdeep Kajla</td>
                  <td className="fs-4">21-90-2024</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllReferrals;
