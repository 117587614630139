import React, { useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import Header from "../HomeComp/Header";
import P2PPostAd from "./P2PAddPost";
import P2PPostAd2 from "./P2PAddPost2";
import P2PPostAdd3 from "./P2PPostAdd3";
import Footer from "../HomeComp/Footer";
import CreateOrder1 from "./CreateOrder1";
import CreateOrder2 from "./CreateOrder2";
import CreateOrder3 from "./CreateOrder3";

const CreateOrderStep = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const steps = [
    { label: "Order Created" },
    { label: "Pay The Seller" },
    { label: "Await Confirmation " },
  ];

  return (
    <>
      <Header />

      <div className="container py-4" style={{ marginTop: "100px" }}>
        {/* Stepper Component */}
        <Stepper
          styleConfig={{
            activeBgColor: "#552dba",
            activeTextColor: "#ffffff",
            inactiveBgColor: "#808080",
            inactiveTextColor: "#000000",
            completedBgColor: "#552dba",
            completedTextColor: "#ffffff",
            size: "2em",
          }}
          activeStep={activeStep}
          className="w-100"
        >
          {steps.map((step, index) => (
            <Step key={index} label={step.label} />
          ))}
        </Stepper>

        {/* Step Content */}
        <div style={{ margin: "20px 0" }}>
          <div className="col-10 m-auto">
            {activeStep === 0 && (
              <div>
                <CreateOrder1 handleNext={handleNext} />
              </div>
            )}
            {activeStep === 1 && (
              <div>
                <CreateOrder2 />
              </div>
            )}
            {activeStep === 2 && (
              <div>
                <CreateOrder3 />
              </div>
            )}
          </div>
        </div>

        {/* Navigation Buttons */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            onClick={handleBack}
            disabled={activeStep === 0}
            className="btn rounded-2 text-white"
            style={{
              width: "150px",
              height: "40px",
              background: "#1C2C60",
            }}
          >
            Back
          </button>
          <button
            onClick={handleNext}
            disabled={activeStep === steps.length - 1}
            className="btn rounded-2 text-white"
            style={{
              width: "150px",
              height: "40px",
              background: "#1C2C60",
            }}
          >
            {activeStep === steps.length - 1 ? "Submit" : "Next"}
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreateOrderStep;
