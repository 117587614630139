import React, { useState } from "react";
import Header from "./HomeComp/Header";
import "./login.css";
import { isEmail } from "./redux/helpers/form-validator.functions";
import { user_forget } from "./redux/actions/authActions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
export default function Forget(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [email, setEmail] = useState();
  const onSubmit = (e) => {
    e.preventDefault();
    if (isEmail(email)) {
      setLoading(true);
      dispatch(
        user_forget(
          email,
          () => {
            setLoading(false);
            props.history?.replace("/forget-password");
          },
          () => setLoading(false)
        )
      );
    }
  };
  return (
    <>
      <Header {...props} />
      <div class="container-fluid register_bg">
        <div class="row ">
        <div class="col-md-7 d-none d-lg-block login_left_bg p-0 ">
            
          </div>
          <div class="col-md-5">           
            <div style={{minHeight:'90vh'}} class="d-flex align-items-center login_page_minheight ">
              <div className="container">
                <div className="row">
                  <div class="m-auto login_window  cstm_form">
                  <div className="d-flex justify-content-center mb-5">
                  <Link to="/">
              <img src="/img/logo.png" style={{width:'80px'}}  />
              </Link>
            </div>
                    <div className="page-content-title">
                      <h3 className="mb-0">Forget Password</h3>
                      <p class="text-muted mb-4 mt-0">
                        Enter email, we'll send you new password in your inbox
                      </p>
                    </div>

                    <form
                      className=""
                      method="post"
                      id="signupform"
                      onSubmit={onSubmit}
                    >
                      <div className="form-group">
                        <small id="msg" style={{ fontSize: "15px" }}>
                          Error message
                        </small>
                      </div>
                      <div className="form-group">
                        {/* <label htmlFor="user_email">Email Address</label> */}
                        <input
                          type="email"
                          name="user_email"
                          required=""
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="form-control"
                          id="user_email"
                          placeholder="Email"
                        />
                        <i className="fas fa-check-circle"></i>
                        <i className="fas fa-exclamation-circle"></i>
                        <small>Error message</small>
                      </div>
                      <div className="d-grid">
                        {" "}
                        <button
                          type="submit"
                          id="btn_submit"
                          className="btn login_btn"
                        >
                          <i className="loading-icon fas fa-spinner fa-spin me-2 h"></i>
                          <span id="reg">Send</span>
                        </button>
                      </div>
                      <div className="text-center text-muted mt-2">
                        <Link className="text-muted" to="/login">
                          {" "}
                          Back to Login Page{" "}
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
