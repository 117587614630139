import React, { useState } from "react";
import "./CustomStepper.css";

const CustomStepper = () => {
  const steps = [
    { label: "Step 1", description: "Save Image" },
    {
      label: "Step 2",
      description: "Log into your own account on the supported app",
    },
    { label: "Step 3", description: "Scan to pay " },
  ];

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);
  const handleReset = () => setActiveStep(0);

  return (
    <div className="custom-stepper">
      {steps.map((step, index) => (
        <div
          key={index}
          className="d-flex justify-content-start align-items-center gap-2"
        >
          <div className="step-header">
            <div className="step-number">{index + 1}</div>
          </div>

          <div className="step-content">
            <p>{step.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CustomStepper;
