import React, { useState } from "react";
import { MdCurrencyExchange } from "react-icons/md";

const FiatDeposit = ({ currencies }) => {
  const [selectedCurrency, setSelectedCurrency] = useState({
    value: "USD",
    label: "USD",
    icon: <MdCurrencyExchange className="text-warning font-bold me-2" />,
  });
  const handleCurrencyChange = (e) => {
    const selected = currencies.find(
      (currency) => currency.value === e.target.value
    );
    setSelectedCurrency(selected);
  };
  return (
    <>
      <div
        id="fiatDeposit"
        className="mx-auto d-flex flex-column gap-5"
        style={{ maxWidth: "480px", marginTop: "50px" }}
      >
        <div
          className="border rounded-4 p-3 mb-3"
          style={{ backgroundColor: "#1a1a1a" }}
        >
          <div className="d-flex justify-content-between">
            <span>Deposit</span>
            <select
              className="order-select form-select text-white bg-transparent w-25"
              style={{ backgroundColor: "#1a1a1a" }}
              onChange={(e) => setSelectedCurrency(e.target.value)} // Add this if you want to handle selection
            >
              <option
                value="USDT"
                disabled
                style={{ backgroundColor: "#1a1a1a" }}
              >
                Select Payment Method
              </option>
              <option value="USD" style={{ backgroundColor: "#1a1a1a" }}>
                Debit Card
              </option>
              <option value="GBP" style={{ backgroundColor: "#1a1a1a" }}>
                Credit Card
              </option>
            </select>
          </div>
          <input
            className="text-muted form-control border-0"
            placeholder="30 - 30000"
            style={{ backgroundColor: "#1a1a1a" }}
          />
        </div>
        <div
          className="border rounded-4 p-3 mb-4"
          style={{ backgroundColor: "#1a1a1a" }}
        >
          <div className=" justify-content-between mb-2 d-flex flex-column">
            <span>Payment Method</span>
            <select
              className="order-select form-select text-white bg-transparent"
              style={{ appearance: "none" }}
              onChange={(e) => setSelectedCurrency(e.target.value)} // Add this if you want to handle selection
            >
              <option
                value="USDT"
                style={{ backgroundColor: "#1a1a1a" }}
                disabled
              >
                Select Payment Method
              </option>
              <option value="USD" style={{ backgroundColor: "#1a1a1a" }}>
                Debit Card
              </option>
              <option value="GBP" style={{ backgroundColor: "#1a1a1a" }}>
                Credit Card
              </option>
            </select>
          </div>
        </div>
        <button
          className={`btn w-100 py-3 fw-bold bg-green rounded-4 text-white`}
        >
          Buy
        </button>
      </div>
    </>
  );
};

export default FiatDeposit;
