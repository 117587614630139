import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

export default function TermsConditions(props) {
  useEffect(() => {
    document.title = "AMEXA Terms & Conditions";
  }, []);
  return (
    <>
      <Header {...props} />
      <div className="wrap-privacy d-flex align-items-center ">
                <div className='container py-5'>
                    <div className="d-block mx-auto">
                    <h1 className="text-left font-size-40">Terms & Condition</h1><br />
                    </div>
                    <p className='para'>These Terms of Use govern access to and use of the mobile application – <span style={{color: "#04DA9A"}}>“AMEXA”</span> (“Platform”) owned and operated by Bitcipher Labs LLP. The term "User" refers to any person/individual who visits, uses, interacts with, or transacts through the website/app/referrals/API or avails of other services (defined below) provided by the Platform.</p>
                    <p className='para'>By accessing, using, or clicking “I agree” to any of the services made available by AMEXA through its Platform, you agree that you have read, understood, and accepted all the terms and conditions contained in these Terms of Use (“Terms/Terms of Use”), our Privacy Policy (available at <a style={{color: "#04DA9A"}} href='https://AMEXA.org/privacy-policy' target='_blank' rel='noreferrer'>https://AMEXA.org/privacy-policy</a>), and AML Policy (available at <a style={{color: "#04DA9A"}} href='https://AMEXA.org/aml-policy' target='_blank' rel='noreferrer'>https://AMEXA.org/aml-policy</a>).</p>
                    <br />
                    <h2>Definitions</h2>
                    <p className='para'>As used herein:</p>
                    <p className='para'><span style={{color: "#04DA9A"}}>*</span> "LLP", "AMEXA", "Ourselves", "Us", "Our", and "We" refer to the owners, operators, employees, directors, officers, agents, insurers, suppliers, and attorneys of the Platform.</p>
                    <p className='para'><span style={{color: "#04DA9A"}}>*</span> "Client", "Customer", "User", "You", and "Your" refer to a resident of India who is at least 18 years of age.</p>
                    <p className='para'><span style={{color: "#04DA9A"}}>*</span> "Virtual Currency" or "VCs" refers to cryptocurrencies (digital money) issued and controlled by their developers and used among members of a specific virtual community, including but not limited to Bitcoin, NEO, Ether, Litecoin, Ripple, Dash, etc. The term “Virtual Currency” or “VC” herein includes only those cryptocurrencies that the LLP, in its sole discretion, decides to facilitate through the Platform.</p>

                    <p className='para'>Save and except as required under applicable law or force majeure events, as long as you agree to and comply with these Terms of Use, AMEXA grants you a limited, personal, non-exclusive, non-transferable, non-sub-licensable, revocable right to access and use the Platform and its services in accordance with these Terms.</p>
                    
                    <p className='para'>You represent and warrant that you:</p>
                    <p className='para'><span style={{color: "#04DA9A"}}>(a) </span> are at least 18 years old and competent to form a binding contract in India,</p>
                    <p className='para'><span style={{color: "#04DA9A"}}>(b) </span> are an individual with full legal capacity and authority to enter into these Terms, and</p>
                    <p className='para'><span style={{color: "#04DA9A"}}>(c) </span> have not previously been suspended or removed from using our services or the Platform.</p>
                    <br />
                    <h2>About AMEXA</h2>
                    <br />
                    <h4><span style={{color: "#04DA9A"}}>1.1</span> AMEXA is an online digital cryptocurrency and forex exchange aggregation platform that facilitates the trading of Virtual Currencies (VCs) and foreign exchange (Forex). The Platform provides access to digital asset and currency exchanges by acting as an aggregator, connecting Users with various global exchanges. Currently, AMEXA facilitates the following services:</h4>
                    <br />
                  
                    <p className='para'><span style={{color: "#04DA9A"}}>(a) </span>Cryptocurrency Trading: Online trading of Virtual Currencies, including the buying and selling of VCs (using fiat currency or another form of VC).</p>
                    <p className='para'><span style={{color: "#04DA9A"}}>(b) </span>Forex Trading: Access to forex trading, allowing Users to trade in major global currency pairs (such as USD/EUR, GBP/USD, etc.) through partner exchanges.</p>
                    <p className='para'><span style={{color: "#04DA9A"}}>(c) </span>Funds Transfer: The transfer of funds between a User’s AMEXA Wallet and their linked bank account, and vice versa.</p>
                    <p className='para'><span style={{color: "#04DA9A"}}>(d) </span>Digital Asset and Forex Storage: Online storage for VCs and forex-related balances owned by Users within the AMEXA Wallet (collectively referred to as "Services").</p>
                 
                    <br />
                    <h4><span style={{color: "#04DA9A"}}>1.2</span> AMEXA is neither the creator nor the administrator of any Virtual Currency or forex platform. It acts solely as an aggregator and technology intermediary, facilitating access to multiple cryptocurrency and forex exchanges through external platforms and APIs.</h4>
                </div>
            </div>
      <Footer />
    </>
  );
}
