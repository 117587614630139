import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Divider,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import "./p2p.css";
import { FaStopwatch, FaThumbsUp } from "react-icons/fa";

const paymentMethods = ["Akbank", "Denizbank", "İŞBANK", "PayPal"];

const styleInput = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      outline: "none",
      borderColor: "transparent",
    },
  },
};

const validationSchema = Yup.object({
  amountPay: Yup.number()
    .required("This field is required")
    .min(1, "Amount must be at least 1"),
  amountReceive: Yup.number()
    .required("This field is required")
    .min(1, "Amount must be at least 1"),
  paymentMethod: Yup.string().required("Please select a payment method"),
});

const P2pDropDown = ({ activeButton }) => {
  const router = useHistory();
  const initialValues = {
    amountPay: "",
    amountReceive: "",
    paymentMethod: "",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    console.log("Form submitted with values:", values);
    setTimeout(() => {
      setSubmitting(false); // Simulate an API call
      router.push("/create-order");
    }, 1000);
  };

  //   MODAL CODE

  const [open, setOpen] = useState(false); // For pop-up visibility
  const [selectedMethod, setSelectedMethod] = useState(""); // For selected value

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSelect = (method) => {
    setSelectedMethod(method);
    handleClose();
  };

  return (
    <Box
      sx={{
        width: "full",
        margin: "auto",
        bgcolor: "#212529",
        p: 4,
        borderRadius: "0px 0px 12px 12px",
      }}
    >
      <Formik
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          values,
        }) => (
          <FormikForm>
            <Grid container spacing={2}>
              {/* Left Panel */}
              <Grid item xs={12} sm={6}>
                <Grid className="d-flex align-items-center gap-4 mb-2">
                  <img
                    src="https://thispersondoesnotexist.com/"
                    alt=""
                    style={{ width: "45px", borderRadius: "4px" }}
                  />
                  <Grid className="d-flex flex-column gap-2">
                    <Typography variant="h6" color="white">
                      Amexa User
                    </Typography>
                    <Typography color="gray">
                      49 orders | 100.00% completion
                    </Typography>
                    <Typography color="gray">
                      <FaThumbsUp /> 99.46% | <FaStopwatch /> 15 min
                    </Typography>
                  </Grid>
                </Grid>

                <Grid className=" p-2 ">
                  <Typography
                    variant="h6"
                    sx={{ mb: 1, fontWeight: "bold" }}
                    color="white"
                  >
                    Advertisers' Terms (Please read carefully)
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      mb: 2,
                      // height: 300,
                      overflow: "auto",
                    }}
                    color="gray"
                  >
                    Here you can provide the advertisers' terms or any other
                    information related to the trade. Make sure the user
                    understands these terms before proceeding. Here you can
                    provide the advertisers' terms or any other information
                    related to the trade. Make sure the user understands these
                    terms before proceeding.
                  </Typography>
                </Grid>
              </Grid>

              {/* Right Panel */}
              <Grid item xs={12} sm={6} sx={{ pl: 2 }}>
                {/* Dynamic Fields */}
                <Box sx={{ mb: 2 }}>
                  <Typography sx={{ mb: 1, fontWeight: "bold" }} color="white">
                    Price: 36.54 TRY
                  </Typography>
                </Box>

                {/* Input Field: You Pay */}
                <Box
                  sx={{
                    mb: 2,
                    border: "1px solid #fff",
                    px: 2,
                    py: 1,
                    borderRadius: "8px",
                  }}
                  className="p2pInputOuterDiv"
                >
                  <Typography variant="body2" sx={{ mb: 1 }} color="gray">
                    You Pay:
                  </Typography>
                  <section className="d-flex justify-content-between align-items-center">
                    <TextField
                      name="amountPay"
                      fullWidth
                      size="small"
                      placeholder="26,850.00 - 46,848.00"
                      // variant="outlined"
                      value={values.amountPay}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.amountPay && Boolean(errors.amountPay)}
                      helperText={touched.amountPay && errors.amountPay}
                      InputProps={{
                        style: { color: "white", border: "none" },
                      }}
                      sx={styleInput}
                    />
                    <Typography
                      marginLeft={1}
                      marginRight={1}
                      sx={{ color: "yellow" }}
                    >
                      ALL
                    </Typography>
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/33/33206.png"
                      alt="img"
                      className="bg-danger rounded-5 p-1"
                      style={{ width: "25px", marginRight: "6px" }}
                    />

                    <Typography>TRY</Typography>
                  </section>
                </Box>

                {/* Input Field: You Receive */}
                <Box
                  sx={{
                    mb: 2,
                    border: "1px solid #fff",
                    px: 2,
                    py: 1,
                    borderRadius: "8px",
                  }}
                  className="p2pInputOuterDiv"
                >
                  <Typography variant="body2" sx={{ mb: 1 }} color="gray">
                    You Receive:
                  </Typography>
                  <section className="d-flex justify-content-between align-items-center">
                    <TextField
                      name="amountReceive"
                      fullWidth
                      size="small"
                      placeholder="0.00"
                      variant="outlined"
                      value={values.amountReceive}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.amountReceive && Boolean(errors.amountReceive)
                      }
                      helperText={touched.amountReceive && errors.amountReceive}
                      InputProps={{
                        style: { color: "white" },
                      }}
                      sx={styleInput}
                    />
                    <img
                      src="https://cdn.iconscout.com/icon/premium/png-512-thumb/tether-usdt-6888834-5645458.png?f=webp&w=256"
                      alt="img"
                      className=""
                      style={{ width: "25px", marginRight: "6px" }}
                    />
                    <Typography>USDT</Typography>
                  </section>
                </Box>

                {/* Payment Method Dropdown */}

                <Button
                  fullWidth
                  onClick={handleOpen}
                  sx={{
                    textAlign: "left",
                    justifyContent: "space-between",
                    color: selectedMethod ? "white" : "gray",
                    border: "1px solid gray",
                    "&:hover": { borderColor: "white" },
                    marginBottom: "40px",
                    padding: "10px",
                  }}
                >
                  {selectedMethod || "Set my payment method"}
                </Button>

                <Dialog
                  open={open}
                  onClose={handleClose}
                  sx={{
                    "& .MuiDialog-paper": {
                      backgroundColor: "#212529",
                      color: "white",
                      width: "450px",
                      height: "500px",
                      borderRadius: "12px",
                    },
                  }}
                >
                  <Grid className="d-flex justify-content-between align-items-center p-4">
                    <Typography size={10}>Payment Method(s)</Typography>
                    <Box onClick={handleClose} sx={{ cursor: "pointer" }}>
                      ✕
                    </Box>
                  </Grid>

                  <DialogContent
                    sx={{
                      position: "relative",
                      color: "white",
                    }}
                  >
                    <List>
                      {paymentMethods.map((method, index) => (
                        <ListItem
                          disablePadding
                          key={index}
                          sx={{
                            border: "1px solid gray",
                            p: 1,
                            borderRadius: "6px",
                            marginBottom: "8px",
                          }}
                        >
                          <ListItemButton
                            onClick={() => handleSelect(method)}
                            sx={{
                              color: "white",
                            }}
                          >
                            {method}
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </DialogContent>
                </Dialog>

                <div className="text-end">
                  <button
                    type="submit"
                    variant="contained"
                    color={activeButton === "Buy" ? "success" : "error"}
                    className="w-100 btn btn-outline-secondary bg-green text-white border-0 px-4"
                    sx={{
                      textTransform: "none",
                      height: "50px",
                      borderRadius: "12px",
                    }}
                    disabled={isSubmitting}
                    onClick={() => {
                      router.push("/create-order");
                    }}
                  >
                    {activeButton === "Buy" ? "BUY USDT" : "SELL USDT"}
                    {isSubmitting && (
                      <CircularProgress size={20} color="inherit" />
                    )}
                  </button>
                </div>
              </Grid>
            </Grid>

            {/* <Divider sx={{ my: 2, borderColor: "gray" }} /> */}

            {/* Buttons */}
          </FormikForm>
        )}
      </Formik>
    </Box>
  );
};

export default P2pDropDown;
