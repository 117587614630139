import React from "react";

const ReferrelData = () => {
  return (
    <>
      <div className="mt-5">
        <h1>Data</h1>
        <div className="refer-data">
          <section>
            <p>Today</p>
            <p className="fs-4">0 User(s)</p>
          </section>
          <section>
            <p>Yesterday's Commission</p>
            <p className="fs-4">≈ 0 USDT</p>
          </section>
          <section>
            <p>Last 30D Referral</p>
            <p className="fs-4">0 User(s)</p>
          </section>
          <section>
            <p>Last 30D Commission</p>
            <p className="fs-4">≈ 0 USDT</p>
          </section>
        </div>
      </div>
    </>
  );
};

export default ReferrelData;
