import { Button } from "@mui/material";
import React, { useState } from "react";
import { BiSolidCheckShield } from "react-icons/bi";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { LuPlus, LuRefreshCw } from "react-icons/lu";
import { VscSettings } from "react-icons/vsc";
import "./p2pStyle.css";
import { Link } from "react-router-dom";
import P2pDropDown from "../modals/P2pDropDown";

const P2PTranding = ({ handleOpenModal, mockData }) => {
  const [activeButton, setActiveButton] = useState("Buy");
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleOpenDropdown = (index) => {
    setSelectedIndex(index === selectedIndex ? null : index); // Toggle dropdown
  };
  return (
    <>
      <div id="p2pTrading">
        <div className="d-flex gap-5 align-items-center">
          <div className="ButtonBox">
            <button
              className={`btn P2pbutton cursor-pointer px-4 py-2 ${
                activeButton === "Buy"
                  ? "P2pbutton2 text-white fw-bold rounded-2"
                  : "text-muted"
              }`}
              onClick={() => setActiveButton("Buy")}
            >
              Buy
            </button>
            <button
              className={`P2pbutton btn cursor-pointer px-4 py-2 ${
                activeButton === "Sell"
                  ? "P2pbutton2 text-white fw-bold rounded-2"
                  : "text-muted"
              }`}
              onClick={() => setActiveButton("Sell")}
            >
              Sell
            </button>
          </div>
          <div className="d-flex gap-5">
            <h5>USDT</h5>
            {/* <h5>SOL</h5>
            <h5>BUSD</h5>
            <h5>BTC</h5> */}
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between p-3 rounded">
          {/* Currency Selector and Input */}
          <div
            className="d-flex align-items-center"
            style={{
              backgroundColor: "#333",
              padding: "5px 10px",
              borderRadius: "5px",
            }}
          >
            <div className="dropdown me-2">
              <button
                className="btn btn-secondary dropdown-toggle d-flex align-items-center"
                type="button"
                id="currencyDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "white",
                  padding: "0",
                }}
              >
                <img
                  src="https://img.icons8.com/color/48/000000/us-dollar-circled--v1.png"
                  alt="USD"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                />
                USD
              </button>
              <ul className="dropdown-menu" aria-labelledby="currencyDropdown">
                <li>
                  <a
                    className="dropdown-item d-flex align-items-center"
                    href="#"
                  >
                    <img
                      src="https://img.icons8.com/color/48/000000/us-dollar-circled--v1.png"
                      alt="USD"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                    />
                    USD
                  </a>
                </li>
                {/* <li>
                  <a
                    className="dropdown-item d-flex align-items-center"
                    href="#"
                  >
                    <img
                      src="https://img.icons8.com/color/48/000000/euro-circled--v1.png"
                      alt="EUR"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                    />
                    EUR
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item d-flex align-items-center"
                    href="#"
                  >
                    <img
                      src="https://img.icons8.com/color/48/000000/indian-rupee.png"
                      alt="INR"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                    />
                    INR
                  </a>
                </li> */}
              </ul>
            </div>
            <input
              type="text"
              className="form-control text-white"
              placeholder="Enter amount"
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "white",
                flex: 1,
              }}
            />
            <button
              type="button"
              className="btn"
              style={{
                backgroundColor: "transparent",
                border: "none",
              }}
            >
              <i className="bi bi-search" style={{ color: "white" }}>
                {" "}
                <IoIosSearch className="fs-4" />
              </i>
            </button>
          </div>

          {/* Right-side Actions */}
          <div
            className="d-flex align-items-center"
            style={{
              backgroundColor: "#333",
              padding: "5px 10px",
              borderRadius: "5px",
            }}
          >
            <div className="dropdown me-3">
              <button
                className="btn dropdown-toggle text-white"
                type="button"
                id="paymentDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                All Payment
              </button>
              <ul className="dropdown-menu" aria-labelledby="paymentDropdown">
                <li>
                  <a className="dropdown-item" href="#!">
                    All Payment
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#!">
                    Credit Card
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#!">
                    PayPal
                  </a>
                </li>
              </ul>
            </div>
            <button className="btn text-white me-3">
              <VscSettings />
              Filter
            </button>
            <button className="btn text-white">
              <i className="bi bi-arrow-clockwise"></i>
              <LuRefreshCw /> Refresh
            </button>
            <Link to={"/P2PPostAd"} className="btn text-white">
              <i className="bi bi-arrow-clockwise"></i>
              <LuPlus /> Post New Ad
            </Link>
            <div className="dropdown me-3">
              <button
                className="btn dropdown-toggle text-white"
                type="button"
                id="paymentDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                More
              </button>
              <ul
                className="dropdown-menu bg-black "
                aria-labelledby="paymentDropdown"
              >
                <li className="">
                  <Link
                    to={"/payment-method"}
                    className="dropdown-item hover-bg-gray"
                  >
                    <LuPlus /> Payment Method
                  </Link>
                  <a className="dropdown-item hover-bg-gray" href="#!">
                    <LuPlus /> My Ads
                  </a>
                  <a className="dropdown-item hover-bg-gray" href="#!">
                    <LuPlus /> Add New Payment Method
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* BUY  */}
        {activeButton === "Buy" && (
          <div className="col-lg-12 col-md-12 col-sm-12 mt-3 table-responsive">
            <table className="table p2p_buy_sell">
              <thead>
                <tr>
                  <th>Advertier</th>
                  <th>Price</th>
                  <th>Available/Limit</th>
                  <th>Payment Method</th>
                  <th>Trade</th>
                </tr>
              </thead>
              <tbody>
                {mockData.map((item, index) => (
                  <>
                    <tr key={index}>
                      <td>
                        <div className="d-flex gap-2 align-items-start">
                          {/* <img
                            src={item.image}
                            className="gray_border p2p_user_img me-2"
                          /> */}
                          <div class="circle_backdrop_outline gray_border">
                            {item.merchant &&
                              item.merchant
                                ?.split("")
                                .slice(0, 2)
                                .join("")
                                .toUpperCase()}
                          </div>

                          <div className="product_name d-flex flex-column gap-2">
                            <div className="mb-0 h6 text-white desc_card">
                              {item.merchant}
                              <span>
                                <BiSolidCheckShield className="text-green" />
                              </span>
                            </div>
                            <div className=" fs-12 text-white">
                              {item.orders} Order(s)
                              <div className="verticle_line"></div>
                              {item.successRate} completion
                            </div>
                            <div className=" fs-12 text-white">
                              {item.per}
                              <div className="verticle_line"></div>
                              {item.time}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="">
                          <span className="desc_card text-white">
                            {item.price}
                          </span>
                          <span className="ms-1 text-white">
                            {item.currency}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="mb-0 d-flex flex-column gap-2 fs-6 text-white">
                          {" "}
                          <span className="">{item.available}USDT</span>{" "}
                          <span className="">{item.limit}</span>
                        </div>{" "}
                      </td>
                      <td>
                        <div>
                          <ul className="d-flex flex-column gap-2 text-white">
                            {item.paymentMethods.map((method) => (
                              <li>
                                <span className="text-warning me-2 text-bold">
                                  |
                                </span>
                                {method}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </td>
                      <td>
                        <div>
                          <button
                            className="btn btn-outline-secondary bg-green text-white px-4 border-0"
                            onClick={() => handleOpenDropdown(index)}
                          >
                            Buy USDT
                          </button>
                        </div>
                      </td>
                    </tr>
                    {selectedIndex === index && (
                      <tr>
                        <td colSpan="5">
                          <div className="dropdown-container">
                            <P2pDropDown activeButton={activeButton} />
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* SELL */}
        {activeButton === "Sell" && (
          <div className="col-lg-12 col-md-12 col-sm-12 mt-3 table-responsive">
            <table className="table p2p_buy_sell">
              <thead>
                <tr>
                  <th>Advertier</th>
                  <th>Price</th>
                  <th>Available/Limit</th>
                  <th>Payment Method</th>
                  <th>
                    Operation{" "}
                    <span className="custom_badge bg-soft-success fs-12">
                      0 Fee(s)
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {mockData.map((item, index) => (
                  <>
                    <tr key={index}>
                      <td>
                        <div className="d-flex gap-2 align-items-start">
                          {/* <img
                            src={item.image}
                            className="gray_border p2p_user_img me-2"
                          /> */}
                          <div class="circle_backdrop_outline gray_border">
                            {item.merchant &&
                              item.merchant
                                ?.split("")
                                .slice(0, 2)
                                .join("")
                                .toUpperCase()}
                          </div>

                          <div className="product_name d-flex flex-column gap-2">
                            <div className="mb-0 h6 text-white desc_card">
                              {item.merchant}
                              <span>
                                <BiSolidCheckShield className="text-green" />
                              </span>
                            </div>
                            <div className=" fs-12 text-white">
                              {item.orders} Order(s)
                              <div className="verticle_line"></div>
                              {item.successRate} completion
                            </div>
                            <div className=" fs-12 text-white">
                              {item.per}
                              <div className="verticle_line"></div>
                              {item.time}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span className="desc_card text-white">
                            {item.price}
                          </span>
                          <span className="ms-1 text-white">
                            {item.currency}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="mb-0 d-flex flex-column gap-2 fs-6 text-white">
                          {" "}
                          <span className="">{item.available}USDT</span>{" "}
                          <span className="">{item.limit}</span>
                        </div>{" "}
                      </td>
                      <td>
                        <div>
                          <ul className="d-flex flex-column gap-2 text-white">
                            {item.paymentMethods.map((method) => (
                              <li>
                                <span className="text-warning me-2 text-bold">
                                  |
                                </span>
                                {method}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </td>
                      <td>
                        <div>
                          <button
                            className="btn btn-outline-secondary bg-danger text-white px-4 border-0"
                            onClick={() => handleOpenDropdown(index)}
                          >
                            Sell USDT
                          </button>
                        </div>
                      </td>
                    </tr>
                    {selectedIndex === index && (
                      <tr>
                        <td colSpan="5">
                          <div className="dropdown-container">
                            <P2pDropDown />
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* pagination */}

        <nav aria-label="Page navigation ">
          <ul className="pagination gap-3 justify-content-end mt-2">
            <li className="page-item" id="prevPage">
              <span className="page-link rounded">
                <FaChevronLeft className="text-primary" />
              </span>
            </li>
            <li className="page-item  d-flex gap-1" id="currentPage">
              <span className="page-link btn-pink rounded active">1</span>
              <span className="page-link rounded">2</span>
              <span className="page-link rounded">3</span>
            </li>
            <li className="page-item " id="nextPage ">
              <a className="page-link rounded" href="#">
                <FaChevronRight className="text-primary" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
      {/* <P2PBuySell open={showModal1} handleClose={handleCloseModal1} /> */}
    </>
  );
};

export default P2PTranding;
