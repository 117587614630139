import React, { useEffect, useState } from "react";
import Header from "../HomeComp/Header";
import Footer from "../HomeComp/Footer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { N_getPaymentMethods } from "../redux/helpers/api_functions_new";
const PaymentMethod = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    N_getPaymentMethods().then((res) => {
      if (res.status == 200) {
        console.log(res, "ressss");
        setData(res.data);
      }
    });
  }, []);
  return (
    <>
      <Header />
      <div className="container" style={{ marginTop: "100px" }}>
        <div className="d-flex justify-content-between align-items-center">
          <section className="col-md-8">
            <h4>Payment Methods</h4>
            <p>
              P2P payment methods: When you sell cryptocurrencies, the payment
              method added will be displayed to buyer as options to accept
              payment, please ensure that the account owner’s name is consistent
              with your verified name on Binance. You can add up to 20 payment
              methods.
            </p>
          </section>
          <section className="col-md-2 pay_meth_dropdown">
            <button
              className="dropdown-toggle btn bg-primary "
              id="payments"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Add Payment Method
            </button>
            <ul className="dropdown-menu p-2" aria-labelledby="payments">
              <li className="dropdown-item ">
                <Link to={"/payment-method/imps"}>IMPS</Link>
              </li>
              <li className="dropdown-item ">
                <Link to={"/bank-transfer"}>Bank Transfer (Turkey)</Link>
              </li>
            </ul>
          </section>
        </div>
        <section className="pay_meth_listing">
          <div className="row justify-content-center">
            <div className="col-8">
              {data.map((item, i) => (
                <div key={i} className="card pay_meth_listing_box mb-4">
                  <div className="card-header">{item?.method}</div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-4">
                        <p className="box-p">Name</p>
                        <h6 className="box-h6">{item?.name}</h6>
                      </div>
                      <div className="col-4">
                        <p className="box-p">Account</p>
                        <h6 className="box-h6">{item?.account_number}</h6>
                      </div>
                      <div className="col-4">
                        <p className="box-p">Email</p>
                        <h6 className="box-h6">{item?.email}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default PaymentMethod;
