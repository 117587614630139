import React from "react";
import ChatBox from "./payment-methods/ChatComponent";
import { Step, Stepper } from "react-form-stepper";
import CustomStepper from "./p2pComponents/CustomStepper";

const CreateOrder2 = () => {
  return (
    <>
      <div className="container">
        <section>
          <h1>Pay the Seller</h1>
          <p>Order Created, Pay the Seller within</p>
          <p>Order number  22714969631719280640</p>
        </section>
        <div className="d-flex gap-4">
          <div
            className="d-flex flex-column gap-3 back-borders p-4 col-md-6"
            style={{ height: "600px", overflow: "auto" }}
          >
            <div className="border rounded-3 p-3">
              <h5>
                <span className="text-green">Buy</span> USDT
              </h5>
              <p className="d-flex justify-content-between">
                <span>Fiat Amount</span>
                <span>$100</span>
              </p>
              <p className="d-flex justify-content-between">
                <span>Price</span>
                <span>$1</span>
              </p>
              <p className="d-flex justify-content-between">
                <span>Receive Quantity</span>
                <span>100 USDT</span>
              </p>
              <p className="d-flex justify-content-between">
                <span>Total Quantity</span>
                <span>100 USDT</span>
              </p>
              <p className="d-flex justify-content-between">
                <span>Fee</span>
                <span>0.05000000 USDT</span>
              </p>
            </div>
            <div className="border rounded-3 p-3">
              <p className="d-flex justify-content-between">
                <span>Pay With</span>
                <span>Lightning UPI</span>
              </p>
              <p className="d-flex justify-content-between">
                <span>Name</span>
                <span>SHUBHAM SETHI</span>
              </p>
              <p className="d-flex justify-content-between">
                <span>Digital eRupee wallet VPA</span>
                <span>idfc</span>
              </p>
              <p className="d-flex justify-content-between">
                <span>You Pay </span>
                <span>100$</span>
              </p>
            </div>
            <div className="border rounded-3 p-3 d-flex  aligh-items-center justify-content-between">
              <CustomStepper />
              <img
                src="https://cdn.pixabay.com/photo/2013/07/12/14/45/qr-code-148732_640.png"
                alt="Qr"
                className=" p-2"
                style={{
                  background: "#ffffff",
                  width: "150px",
                  height: "150px",
                }}
              />
            </div>
          </div>
          <div
            className="back-borders col-md-6"
            style={{
              height: "600px",
              overflow: "auto",
              position: "relative",
              background: "#5f5f5f",
            }}
          >
            <ChatBox />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateOrder2;
