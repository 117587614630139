import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import Loader from "../components/Loader";
import AOS from "aos";
import "aos/dist/aos.css";
import { getRound } from "../redux/helpers/helper_functions";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useHumanReadableNumber from "../../hooks/useHumanReadableNumber";
import { Sparklines, SparklinesCurve, SparklinesLine } from "react-sparklines";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
export default function MarketTrend() {
  const formatNumber = useHumanReadableNumber();
  const convertStringToNumber = (input) => {
    // Ensure the input is a string
    const str = String(input);

    const cleanedString = str.replace(/,/g, ""); // Remove commas
    return parseFloat(cleanedString); // Convert to number
  };
  const [loader, setLoader] = useState(true);
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  function formatNumberWithCommas(number, locales = "en-US", options = {}) {
    // Set default options if not provided
    const defaultOptions = {
      minimumFractionDigits: 3, // Ensures small numbers like 0.001 are displayed correctly
      maximumFractionDigits: 4, // Adjust this based on the precision you want
    };

    // Merge user-provided options with default options
    const formatOptions = { ...defaultOptions, ...options };

    return new Intl.NumberFormat(locales, formatOptions).format(number);
  }
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1200,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
    ],
  };
  AOS.init();

  const preferredOrder = ["BTC", "ETH", "XRP", "USDT", "SOL", "BNB", "DOGE"];
  const { coins, campare_currency } = useSelector(
    (state) => state.coinDBReducer
  );

  let coins1 = Object.values(coins);
  useEffect(() => {
    if (coins1?.length == 0) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [coins]);
  // Sort coins1 based on preferredOrder
  coins1.sort((a, b) => {
    const indexA = preferredOrder.indexOf(a.symbol);
    const indexB = preferredOrder.indexOf(b.symbol);
    if (indexA === -1) return 1; // If a.symbol is not in preferredOrder, move it to the end
    if (indexB === -1) return -1; // If b.symbol is not in preferredOrder, move it to the end
    return indexA - indexB;
  });

  const market_child =
    coins1 &&
    coins1.map((item, index) => {
      let usdtprize = coins1 && coins1.find((item) => item.symbol == "USDT");

      return (
        <>
          {item.symbol != "USDT" ? (
            <tr className="table_row_border">
              <td>
                <img
                  src={item.icon}
                  alt="coin symbole"
                  className="market_coin_img"
                  //style={{ width: "50px" }}
                />
                {item.symbol}
                <span className="name_coin ms-2">{item.name}</span>
              </td>
              <td>
                $
                {formatNumberWithCommas(
                  getRound(item?.current_price / usdtprize?.current_price)
                )}
              </td>
              <td>
                <span
                  style={{
                    color: item.direction === "up" ? "green" : "#e2464a",
                  }}
                >
                  {item.direction === "up" ? "+" : "-"}{" "}
                  {getRound(item.price_change_percentage_1h)}%{" "}
                  {item.direction === "up" ? (
                    <BsArrowUpShort />
                  ) : (
                    <BsArrowDownShort />
                  )}
                </span>
              </td>
              <td>
                $
                {formatNumber(
                  convertStringToNumber(item.market_cap) /
                    usdtprize?.current_price
                )}
              </td>
              <td class="text-end pe-5">
                <Sparklines>
                  <SparklinesLine color="" data={item.h_trend} />
                </Sparklines>

                <Sparklines data={item.h_trend}>
                  <SparklinesCurve
                    color={item.direction == "up" ? "#00FF00" : "#FF4500"} // Bright green or orange for better contrast
                    style={{
                      strokeWidth: 2,
                      strokeLinejoin: "round",
                      strokeLinecap: "round",
                      filter: "drop-shadow(0 0 5px rgba(255, 255, 255, 0.7))", // Brighter shadow for better visibility
                    }}
                  />
                </Sparklines>
              </td>
              <td>
                <NavLink
                  to={`/exchange/${item.symbol.toLowerCase()}-${campare_currency}`}
                >
                  <button className="btn-pink btn px-4">Trade</button>
                </NavLink>
              </td>
            </tr>
          ) : (
            ""
          )}
        </>
      );
    });
  return (
    <>
      <div className="spacer" id="trade_now">
        <div className="container">
          <div className="para_heading">
            Capture Every <span className="text-gradient">Trading</span>
            <br /> Opportunity.
          </div>
          <div className="market_trend_section">
            <div className="overflow-auto">
              <div className="table_scroll_div">
                <table className="table home_table">
                  <thead>
                    <tr>
                      <th>Trading Pair</th>
                      <th>Last Price</th>
                      <th>24h Change</th>
                      <th>Market Cap</th>
                      <th>4H Trend</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loader ? (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          <Loader />
                        </td>
                      </tr>
                    ) : (
                      market_child
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="secure_assets_section">
        <div className="container">
          <div className="para_heading text-center">
            Protect Your <span className="text-gradient">Assets</span>.
          </div>
          <div className="about_card_div">
            <div className="row">
              <div className="col-md-4">
                <div className="about_card about_card_border_right">
                  <div className="my-2">
                    <img style={{ width: "60px" }} src="/img/secure1.png" />
                  </div>
                  <div className="about_card_heading mt-4 mb-2">
                    Compliance Matrix
                  </div>
                  <div className="about_card_content">
                    Take control of your financial security with Amexa—ensuring
                    transparency, trust, and state-of-the-art protection for
                    your investments.{" "}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="about_card about_card_border_right">
                  <div className="my-2">
                    <img style={{ width: "60px" }} src="/img/secure2.png" />
                  </div>
                  <div className="about_card_heading mt-4 mb-2">
                    Advanced Security Protocols
                  </div>
                  <div className="about_card_content">
                    Protect your investments with cutting-edge security
                    features, including two-factor authentication (2FA) and
                    advanced AI-driven risk detection. At Amexa, your assets are
                    safeguarded with robust, state-of-the-art technology.{" "}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="about_card about_card_border_right">
                  <div className="my-2">
                    <img style={{ width: "60px" }} src="/img/secure3.png" />
                  </div>
                  <div className="about_card_heading mt-4 mb-2">
                    Transparency You Can Trust{" "}
                  </div>
                  <div className="about_card_content">
                    Amexa provides 100% Proof-of-Reserves to guarantee the
                    safety and integrity of your funds. Partnered with top-tier
                    cybersecurity organizations and validated by
                    industry-leading audit platforms, we prioritize trust and
                    accountability in every transaction.{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" mt-4 app_section">
        <div className="container">
          <div className="row">
            <div className="para_heading text-center ">
              Accelerate your path to market & unlock{" "}
              <br className="d-none d-md-block" />
              <span className="text-gradient">your full revenue potential</span>
            </div>
            <div className="col-12 my-5">
              <div className="d-flex justify-content-center flex-column gap-5">
                <img className="px-5 move_img1" src="/img/dummy_slide1.png" />
                <img className="move_img2" src="/img/dummy_slide2.png" />
                <img className="px-5 move_img3" src="/img/dummy_slide3.png" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" key_features trade_banner mb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 m-auto">
              <div className="para_heading">
                Trade Anytime,
                <br />
                <span className="text-gradient">Anywhere.</span>
              </div>
              <div className="para_subheading mt-4">
                Take control of your trades on the go. With Amexa's intuitive
                app and web platform, you can open positions instantly and
                manage your investments with ease. Whether you're on your
                smartphone or desktop, our technology ensures you never miss a
                market opportunity.
              </div>
              <h3 className="mt-3">Available on:</h3>
              <div className="d-flex  gap-4 mt-4">
                <img style={{ width: "160px" }} src="/img/appstore.png" />
                <img style={{ width: "160px" }} src="/img/playstore.png" />
              </div>
            </div>
            <div className="col-md-6 d-none d-md-block">
              <img src="/img/trade.png" />
            </div>
          </div>
        </div>
      </div>

      <div className=" key_features ">
        <div className="container">
          <div className="para_heading text-center">
            Why
            <span className="text-gradient"> Amexa </span>Stands Out
          </div>
          <div className="row  mt-md-5 mt-2">
            <div className="col-md-4 mt-5">
              <div className="why_amexa_card">
                <img
                  style={{ width: "60px" }}
                  className="mb-4"
                  src="/img/why_amexa1.png"
                />
                <div className="why_amexa_card_heading">
                  Simplify Your Trading Experience
                </div>
                <div className="why_amexa_card_content">
                  Learn, Invest, Trade and Earn efficiently and smoothly with
                  Amexa.
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-5">
              <div className="why_amexa_card">
                <img
                  style={{ width: "60px" }}
                  className="mb-4"
                  src="/img/why_amexa2.png"
                />
                <div className="why_amexa_card_heading">
                  Embark on an Innovative Trading Journey
                </div>
                <div className="why_amexa_card_content">
                  Bridging the gap between Tradition and Innovation with tools
                  NEVER SEEN BEFORE{" "}
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-5">
              <div className="why_amexa_card">
                <img
                  style={{ width: "60px" }}
                  className="mb-4"
                  src="/img/why_amexa3.png"
                />
                <div className="why_amexa_card_heading">
                  Complete Control at Your Fingertips
                </div>
                <div className="why_amexa_card_content">
                  Amexa provides all the tools you will ever need to build your
                  dream portfolio.Technical Support, Customer Support, You name
                  it we got it. From Risk Management tools to ethical trading,
                  Amexa has got your back.{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="spacer key_features ways mt-5">
        <div className="container">
          <div className="para_heading text-center">
            Ways to use <span className="text-gradient">AMEXA</span>
          </div>
          <div className="row  mt-5">
            <Slider {...settings}>
              <div className="">
                <div className="ways_card">
                  <div className="ways_heading ">
                    Integrated Wallet for Crypto, Forex, and Fiat
                  </div>

                  <div className="ways_content custom_border_gradient pt-3 pb-2 pb-md-5">
                    <br />
                    <br />
                    Amexa’s all-in-one feature allows you to trade and manage
                    various currencies effortlessly and securely.
                  </div>
                </div>
              </div>
              <div className="">
                <div className="ways_card">
                  <div className="ways_heading ">
                    Swap-Free Trading Accounts
                  </div>

                  <div className="ways_content custom_border_gradient pt-3 pb-2 pb-md-5">
                    <br />
                    <br />
                    Amexa offers swap-free accounts tailored for traders who
                    prefer not to engage in interest-based transactions. No
                    swap, More fun.{" "}
                  </div>
                </div>
              </div>
              <div className="">
                <div className="ways_card">
                  <div className="ways_heading ">Competitive Spreads</div>

                  <div className="ways_content custom_border_gradient pt-3 pb-2 pb-md-5">
                    <br />
                    <br />
                    Enjoy tight spreads for all your trading needs.
                    <ul>
                      <li>Forex spreads as low as 2 pips.</li>
                      <li>
                        Cryptocurrency trades with a competitive 0.02% spread,
                        ensuring cost efficiency.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="ways_card">
                  <div className="ways_heading ">Staking Options</div>
                  <div className="ways_content custom_border_gradient pt-3 pb-2 pb-md-5">
                    <br />
                    <br />
                    Earn passive income with Amexa’s staking feature.
                    <ul>
                      <li>
                        Simply hold select cryptocurrencies on the platform to
                        generate returns.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="ways_card">
                  <div className="ways_heading ">
                    {" "}
                    Advanced Charting Tools with Supercharts
                  </div>
                  <div className="ways_content custom_border_gradient pt-3 pb-2 pb-md-5">
                    <br />
                    <br />
                    Analyze market trends and make informed decisions with
                    Amexa’s advanced charting tools.
                    <ul>
                      <li>
                        Access a range of technical indicators and visualization
                        options for a superior trading experience.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="ways_card">
                  <div className="ways_heading "> High Leverage Options</div>
                  <div className="ways_content custom_border_gradient pt-3 pb-2 pb-md-5">
                    <br />
                    <br />
                    Expand your trading potential with leverage options tailored
                    to your needs.
                    <ul>
                      <li>
                        Experience the power of high leverage and take your
                        trading to the next level with Amexa. Your success is
                        our priority.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="ways_card">
                  <div className="ways_heading "> P2P Trading Features</div>
                  <div className="ways_content custom_border_gradient pt-3 pb-2 pb-md-5">
                    <br />
                    <br />
                    Facilitate direct, secure trades between users through
                    Amexa’s peer-to-peer system.
                    <ul>
                      <li>Designed for safe and transparent transactions.</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="ways_card">
                  <div className="ways_heading ">
                    {" "}
                    No Inactivity or Withdrawal Fees{" "}
                  </div>
                  <div className="ways_content custom_border_gradient pt-3 pb-2 pb-md-5">
                    <br />
                    <br />
                    Focus on trading without worrying about hidden costs.
                    <ul>
                      <li>
                        Amexa ensures a fee-free experience for inactive
                        accounts or withdrawals.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="ways_card">
                  <div className="ways_heading ">
                    {" "}
                    Education and Community Building
                  </div>
                  <div className="ways_content custom_border_gradient pt-3 pb-2 pb-md-5">
                    <br />
                    <br />
                    Amexa empowers traders through comprehensive learning
                    resources.
                    <ul>
                      <li>
                        Access tutorials, guides, and community features to
                        enhance your trading expertise.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="ways_card">
                  <div className="ways_heading ">
                    {" "}
                    Ethical and Transparent Operations
                  </div>
                  <div className="ways_content custom_border_gradient pt-3 pb-2 pb-md-5">
                    <br />
                    <br />
                    Licensed by FCA and FSA, Amexa prioritizes regulatory
                    compliance and ethical trading.
                    <ul>
                      <li>
                        Ensures the security of your funds and a fair trading
                        environment for all users.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}
