import React from "react";
import ProfileSidebar from "./components/ProfileSidebar";
import Header from "./HomeComp/Header";
import { useSelector } from "react-redux";

export default function Cupon(props) {
  const { webData } = useSelector((state) => state.websiteDBReducer);
  return (
    <div>
      <Header {...props} />
      <div className="col-lg-12">
        
          {/* <ProfileSidebar {...props} /> */}
        
        <div className="main-content">
          <div className="mt-3">
            <h4 className="mb-3">Coupon Rewards</h4>

            <div className="card">
              <div className="card-header">
                Claim your coupon reward on {webData.website_title}
              </div>
              <div className="card-body">               
                <div className="">  
                <div className="text-muted mb-3 fs-14">
                    Enter your coupon code and perform the required activities
                    to claim your reward.{" "}
                  </div>                 
                 
                  <div className="col-lg-5">
                   
                    <div className="input-group">
                    <span className="input-group-text">
                        <i className="fa fa-gift" aria-hidden="true"></i>
                      </span>
                      <input
                        type="email"
                        className="form-control"
                        id=""
                        placeholder="Enter Code"
                      />
                      <span className="input-group-text bg-primary">
                      <a
                          href="#support"
                          className="text-white mb-1"
                        >
                          {" "}
                          Apply
                        </a>
                      </span>
                    </div>
                  
                  </div>
                  
                </div>               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
