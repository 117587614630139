import React from "react";

const ReferralCodes = () => {
  return (
    <>
      <div className="mt-5">
        <h1>Referrals</h1>
        <div className="d-flex flex-column gap-4">
          <div className="refer-data">
            <section>
              <p>Referral Code</p>
              <p className="fs-4">XMMXKN</p>
            </section>
            <section>
              <p>My Commission Ratio</p>
              <p className="fs-4">10.00% </p>
            </section>
            <section>
              <p>Referees' Rebate Ratio</p>
              <p className="fs-4">NIL </p>
            </section>
            <section>
              <p> Last 30D Referral</p>
              <p className="fs-4"> 0 </p>
            </section>
            <section>
              <p> Last 30D Commission</p>
              <p className="fs-4"> ≈ 0 USDT </p>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferralCodes;
