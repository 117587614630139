import React, { useState } from "react";
import "./refer.css";
import Header from "../HomeComp/Header";
import { BsEye } from "react-icons/bs";
import Footer from "../HomeComp/Footer";
import { BiCopy, BiInfoCircle } from "react-icons/bi";
import ReferrelData from "./ReferrelData";
import AllReferrals from "./AllReferrals";
import ReferralCodes from "./ReferralCodes";

const ReferPage = () => {
  const [activeEye, setActiveEye] = useState(false);
  const eyeView = () => {
    setActiveEye(!activeEye);
  };
  const copyText = (text) => {
    window.navigator.clipboard.writeText(text);
  };
  return (
    <>
      <Header />
      <div
        className="container d-flex flex-column gap-5 refer-page"
        style={{ marginTop: "100px", marginBottom: "20px" }}
      >
        <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center col-12">
          <section className="col-lg-6">
            <h1 className="heading-txt">Refer Friends, Earn Crypto Together</h1>
            <p className="heading-txt1">
              Share commissions in spot and futures trading
            </p>
          </section>
          <div
            className="refer-box col-lg-5 d-flex flex-column gap-3"
            style={{ background: "#0E0E0E" }}
          >
            <section className="d-flex justify-content-between align-items-center">
              <p>
                Default Referral{" "}
                <span>
                  <BsEye onClick={eyeView} className="ms-2" />
                </span>
              </p>
              <p> Manage benefits</p>
            </section>
            <section className="d-flex flex-column">
              <p>My commission ratio</p>
              <h1>{activeEye ? "10.00%" : "****"}</h1>
            </section>
            <section className="d-flex justify-content-between align-items-center border rounded-2 p-3">
              <p>Referral Code</p>
              <p>
                XMMXKN{" "}
                <span>
                  <BiCopy onClick={() => copyText("XMMXKN")} className="ms-2" />
                </span>
              </p>
            </section>
            <section className="d-flex justify-content-between align-items-center border rounded-2 p-3">
              <p>Referral Link</p>
              <p>
                https://bingx.pro/invite/XMMXKN/
                <span>
                  <BiCopy
                    onClick={() => copyText("https://bingx.pro/invite/XMMXKN/")}
                    className="ms-2"
                  />
                </span>
              </p>
            </section>
            <button className="referBtn btn col-12">Refer Now</button>
          </div>
        </div>
        <div>
          <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
            <button
              className="nav-link active"
              id="data-tab"
              data-bs-toggle="tab"
              data-bs-target="#data"
              type="button"
              role="tab"
            >
              Data
            </button>
            <button
              className="nav-link"
              id="referals-tab"
              data-bs-toggle="tab"
              data-bs-target="#referals"
              type="button"
              role="tab"
            >
              Referrals
            </button>
            <button
              className="nav-link"
              id="commissions-tab"
              data-bs-toggle="tab"
              data-bs-target="#commissions"
              type="button"
              role="tab"
            >
              Commissions
            </button>
            <button
              className="nav-link"
              id="referral-codes-tab"
              data-bs-toggle="tab"
              data-bs-target="#referral-codes"
              type="button"
              role="tab"
            >
              Referral Codes
            </button>
          </div>

          {/* tab content */}
          <div className="tab-content">
            <div
              className="tab-pane fade show active "
              id="data"
              role="tabpanel"
              aria-labelledby="data-tab"
            >
              <ReferrelData />
            </div>
            <div
              className="tab-pane fade"
              id="referals"
              role="tabpanel"
              aria-labelledby="referals-tab"
            >
              <AllReferrals />
            </div>
            <div
              className="tab-pane fade"
              id="commissions"
              role="tabpanel"
              aria-labelledby="commissions-tab"
            >
              <h1>
                My Commission{" "}
                <span>
                  <BiInfoCircle />
                </span>
              </h1>
            </div>
            <div
              className="tab-pane fade"
              id="referral-codes"
              role="tabpanel"
              aria-labelledby="referral-codes-tab"
            >
              <ReferralCodes />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ReferPage;
