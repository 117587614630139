import React, { useState, useEffect, useRef } from "react";

function SelectWithImages({ items }) {
  const [itemToShow, setItemToShow] = useState(items?.[0]);
  const [searchAssets, setSearchAssets] = useState(items);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Handle clicking outside of dropdown to close it
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSearch = (value) => {
    setSearchAssets(
      items?.filter((item) =>
        item?.currencySymbol?.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button
        className="form-control bg-transparent mt-2 d-flex justify-content-between align-items-center text-dark dropdown-toggle"
        onClick={toggleDropdown}
        type="button"
        aria-expanded={isOpen}
      >
        <div className="bg-transparent">
          <span className="btn btn-modal bg-transparent text-dark">
            <div className="d-flex justify-content-between align-items-center gap-2">
              <div className="token-image">
                <img
                  style={{
                    maxHeight: "25px",
                  }}
                  className="rounded-circle"
                  src={itemToShow?.iconUrl}
                  alt=""
                />
              </div>
              <div className="token-name">
                <h6 className="mb-1">{itemToShow?.currencySymbol}</h6>
              </div>
            </div>
          </span>
        </div>
      </button>

      <div
        className={`dropdown-menu w-100 ${isOpen ? "show" : ""}`}
        style={{ height: "200px", overflow: "scroll" }}
      >
        <div className="tokensearch my-2 mx-2">
          <input
            type="search"
            value={search}
            onChange={(e) => {
              handleSearch(e.target.value);
              setSearch(e.target.value);
            }}
            className="form-control"
            placeholder="Search name or paste address"
          />
        </div>
        <div className="popular-tokens">
          <div className="token-list">
            {search && searchAssets.length === 0 ? (
              <h6 className="text-danger text-center">No Asset Found!</h6>
            ) : (
              (search ? searchAssets : items)?.map((item, i) => (
                <button
                  key={i}
                  className="dropdown-item"
                  onClick={() => {
                    setItemToShow(item);
                    setSearch("");
                    setIsOpen(false);
                  }}
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="token-image">
                      <img
                        className="rounded-circle"
                        src={item?.iconUrl}
                        style={{
                          maxHeight: "25px",
                        }}
                        alt=""
                      />
                    </div>
                    <div className="token-name">
                      <h6 className="mb-1">{item?.currencySymbol}</h6>
                    </div>
                  </div>
                </button>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectWithImages;
