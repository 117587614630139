import {
  AUTH_FORGET,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  SEND_OTP,
  F_OTP,
  SEND_AUTH,
  SWITCH_THEME,
  PROFILE,
} from "../constant";
let user_id = localStorage.getItem("exchange-inrx-userid");
let thme = localStorage.getItem("exchange_inrx_theme_frontend");
const initialState = {
  user: {
    id: 0,
    email: "",
    kyc: 0,
    verify: 0,
    // token: session ? session : "",
    user_id: user_id ? user_id : "",
  },
  otp_send: false,
  isLoggedIn: user_id ? true : false,
  switch_theme: thme ? thme : "dark",
  profile: {},
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_LOGIN:
      localStorage.setItem("exchange-inrx-userid", action.data.params.user_id);
      localStorage.setItem("token", action.data.params.token);
      return {
        ...state,
        user: { ...action.data },
        otp_send: false,
        isLoggedIn: true,
      };
    case SEND_OTP:
      return {
        ...state,
        otp_send: true,
        user: { ...action.data },
      };
    case PROFILE:
      return {
        ...state,
        profile: { ...action.data },
      };
    case F_OTP:
      return {
        ...state,
        otp_send: true,
        user: { ...action.data },
      };
    case SEND_AUTH:
      return {
        ...state,
        otp_send: true,
        user: { ...action.data },
      };
    case AUTH_FORGET:
      return {
        ...state,
      };

    case AUTH_LOGOUT:
      localStorage.removeItem("exchange-inrx-userid");
      return {
        ...state,
        isLoggedIn: false,
      };
    case SWITCH_THEME:
      localStorage.setItem("exchange_inrx_theme_frontend", action.data);
      return {
        ...state,
        switch_theme: action.data,
      };

    default:
      return {
        ...state,
      };
  }
}
