import React, { useState } from "react";

const QuickBuy = () => {
  const [activeTabBuy, setActiveTabBuy] = useState("buy");
  const [currencyValue, setCurrencyValue] = useState("");
  return (
    <>
      <div className="banner-section bg-transparent">
        <div className=""></div>
        <div className=""></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-7 mb-3">
              <div className="banner_left_inner">
                <h2 className="bannertxt">Buy Crypto in One Click </h2>
                <h3 className="mb-4">
                  {" "}
                  Pay with the currency and payment method of your choice.
                </h3>

                <div className="payement_method mt-5">
                  <h4 className="mb-3">
                    We support as many as 70+ payment methods.
                  </h4>
                  <img src="./img/master.svg" />
                  <img src="./img/visa.svg" /> <img src="./img/paypal.svg" />{" "}
                  <img src="./img/payement2.svg" />
                  <img src="./img/sepa.svg" />
                  <img src="./img/paymethod.svg" />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <div class="buysell-card mt-2 mb-2">
                <div class=" p-0">
                  <div
                    class="nav nav-pills nav-justified"
                    id="nav-tab"
                    role="tablist"
                  >
                    <div
                      class={`nav-item nav-link ${
                        activeTabBuy == "buy" ? "active" : ""
                      }`}
                      href="#buy-tab"
                      data-toggle="tab"
                      onClick={() => setActiveTabBuy("buy")}
                    >
                      Buy
                    </div>
                    <div
                      class={`nav-item nav-link ${
                        activeTabBuy == "sell" ? "active" : ""
                      }`}
                      href="#sell-tab"
                      data-toggle="tab"
                      onClick={() => setActiveTabBuy("sell")}
                    >
                      Sell
                    </div>
                  </div>
                </div>
                <div class=" custom_scrollbar">
                  <div class="tab-content p-5">
                    <div class="tab-pane active" id="buy-tab">
                      <div className="buysellinput">
                        <label className="small text-muted">
                          I Want to Spend
                        </label>
                        <div class="input-group">
                          <input
                            type="number"
                            class="form-control"
                            onChange={(e) => setCurrencyValue(e.target.value)}
                            value={currencyValue}
                            placeholder="Limit: 10 -5,000"
                          />
                          <span class="">
                            <select class="form-select border-0 bg-transparent text-white">
                              <option value="USD" selected>
                                <span>
                                  <img
                                    src="./img/USD.png"
                                    className="me-1"
                                    width={20}
                                  />
                                  USD
                                </span>
                              </option>
                              <option value="EUR">EUR</option>
                              <option value="GBP">GBP</option>
                            </select>
                          </span>
                        </div>
                      </div>

                      <div className="buysellinput">
                        <label className="small text-muted">
                          I Will Recieve
                        </label>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control "
                            value=""
                            placeholder="Limit: 9.52 - 4,761.9"
                          />
                          <span class="input-group-text">
                            <img
                              src="./img/USDT.png"
                              className="me-1"
                              width={20}
                            />
                            USDT
                          </span>
                        </div>
                      </div>
                      <div>
                        <label className="small text-muted">Pay With</label>
                        <div
                          class="payement pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#paymentmodal"
                        >
                          <img src="./img/visa.svg" />{" "}
                          <img src="./img/master.svg" /> Visa/Master Card
                        </div>
                      </div>

                      <div class="d-grid mt-2">
                        {activeTabBuy == "buy" ? (
                          <button class="btn btn-success btn-lg">BUY</button>
                        ) : (
                          <button class="btn btn-danger btn-lg">SELL</button>
                        )}
                      </div>
                      <span className="small-info">
                        Reference Price:1 USDT ≈ 1.05 USD
                      </span>
                    </div>

                    <div class="tab-pane" id="sell-tab">
                      <div>
                        <label className="small text-muted">
                          I Want to Spend
                        </label>
                        <div class="input-group p2p">
                          <input
                            type="text"
                            class="form-control"
                            onChange={(e) => setCurrencyValue(e.target.value)}
                            value=""
                            placeholder="Limit: 10 -5,000"
                          />
                          <span class="input-group-text">
                            <img
                              src="./img/USDT.png"
                              className="me-1"
                              width={20}
                            />
                            USDT
                          </span>
                        </div>
                      </div>

                      <div>
                        <label className="small text-muted">
                          I Will Recieve
                        </label>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control "
                            value=""
                            placeholder="Limit: 9.52 - 4,761.9"
                          />
                          <span class="input-group-text">
                            {" "}
                            <span class="input-group-text">
                              <img
                                src="./img/USD.png"
                                className="me-1"
                                width={20}
                              />
                              USD
                            </span>
                          </span>
                        </div>
                      </div>

                      <div>
                        <label className="small text-muted">Pay With</label>
                        <div
                          class="payement pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#paymentmodal"
                        >
                          <img src="./img/visa.svg" />{" "}
                          <img src="./img/master.svg" /> Visa/Master Card
                        </div>
                      </div>

                      <div class="d-grid mt-2">
                        <button class="btn btn-danger btn-lg">SELL BTC</button>
                      </div>
                      <span className="small-info">
                        Reference Price:1 USDT ≈ 1.05 USD
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickBuy;
