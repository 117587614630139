import React from "react";
import "../modals/p2p.css";
import SwapAssetsDropdown from "./TermsTagsDropDown";

const P2PPostAdd3 = () => {
  return (
    <>
      <div className="container border rounded-4 p-4 d-flex flex-column gap-3">
        <section className=" rounded-2 p-4" style={{ background: "#2E2A1E" }}>
          <p className="text-white">
            Please ensure that you comply with P2P rules to avoid account
            suspension or expulsion from the Binance Merchant Program.
            Especially: 1. If you require taker's document for verification,
            it's necessary to indicate the requirement in the 'Remarks' section
            of your advertisement. 2. Imposing extra fees on takers is not
            allowed in all scenarios.
          </p>
        </section>
        <section className="col-md-12 mt-4 mb-3 d-flex flex-column gap-3">
          <section className="col-12">
            <label htmlFor="">Terms Tags (Optional)</label>
            <SwapAssetsDropdown />
          </section>
          <section className="col-md-10">
            <span>Select up to 3 tags</span>
          </section>
          <section className="col-12">
            <label htmlFor="">Remarks (Optional)</label>
            <textarea type="textarea" name="" id="" className="textarea1" />
          </section>
          <section className="col-12">
            <label htmlFor="">Auto Reply (Optional)</label>
            <textarea type="text-area" name="" id="" className="textarea1" />
          </section>
          <section className="col-md-10">
            <select id="paymentMethod" className="form-select">
              <option value="Bank">All Tags</option>

              <option value="GPAY" className="d-flex flex-column">
                <input type="checkbox" />
                <span>Bank statement required</span>
                <span>
                  Bank statement will be required for addition verification
                </span>
              </option>
              <option value="Bank">30 MIN</option>
            </select>
            <span>Select up to 3 tags</span>
          </section>
        </section>
        <section className="col-4">
          <section className="d-flex gap-4 align-items-center justify-content-start">
            <input type="checkbox" name="" id="" className="" />
            <span>Registered</span>
            <input type="number" name="" id="" className="form-control1" />
            <span>day(s) ago</span>
          </section>
          <section className="d-flex gap-4 align-items-center justify-content-start">
            <input type="checkbox" name="" id="" />
            <span>Registered</span>
            <input type="number" name="" id="" className="form-control1 " />
            <span>day(s) ago</span>
          </section>
        </section>

        <div className="d-flex gap-5">
          <section>
            <input type="radio" name="" id="" />
            <label htmlFor="">Online</label>
          </section>
          <section>
            <input type="radio" name="" id="" />
            <label htmlFor="">Offline</label>
          </section>
          <section>
            <input type="radio" name="" id="" />
            <label htmlFor="">Private</label>
          </section>
        </div>
      </div>
    </>
  );
};

export default P2PPostAdd3;
