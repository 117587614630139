// import * as React from 'react';
// import { widget } from '../../charting_library';
// import { connect } from 'react-redux';

// class TVChartContainer extends React.PureComponent {
//     constructor(props) {
//         super(props);
//         this.tvWidget = null; // Initialize tvWidget as null
//     }

//     getLanguageFromURL() {
//         const regex = new RegExp('[\\?&]lang=([^&#]*)');
//         const results = regex.exec(window.location.search);
//         return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
//     }

//     widgetOptionsFunc(widgtProp) {
//         let symbols = widgtProp.symbols;

//         if (widgtProp.pre_symbols && widgtProp.pre_symbols !== widgtProp.symbols) {
//             symbols = widgtProp.symbols;
//         }

//         // Safeguard: Check if Datafeeds and UDFCompatibleDatafeed are available
//         if (!window.Datafeeds || !window.Datafeeds.UDFCompatibleDatafeed) {
//             console.error('UDFCompatibleDatafeed is not defined. Ensure the TradingView library is loaded.');
//             return null;
//         }

//         const widgetOptions = {
//             debug: false,
//             symbol: symbols,
//             theme: this.props.theme === 'dark' ? 'Dark' : 'Light',
//             datafeed: new window.Datafeeds.UDFCompatibleDatafeed(widgtProp.datafeedUrl),
//             interval: widgtProp.interval,
//             container_id: widgtProp.containerId,
//             library_path: widgtProp.libraryPath,
//             locale: this.getLanguageFromURL() || 'en',
//             disabled_features: [
//                 'header_compare',
//                 'header_saveload',
//                 'header_settings',
//                 'header_undo_redo',
//                 'header_screenshot',
//                 'header_fullscreen_button',
//                 'main_series_scale_menu',
//                 'countdown',
//                 'go_to_date',
//                 'timeframes_toolbar'
//             ],
//             enabled_features: ['hide_resolution_in_legend'],
//             charts_storage_url: widgtProp.chartsStorageUrl,
//             charts_storage_api_version: widgtProp.chartsStorageApiVersion,
//             client_id: widgtProp.clientId,
//             user_id: widgtProp.userId,
//             fullscreen: widgtProp.fullscreen,
//             autosize: widgtProp.autosize,
//             studies_overrides: widgtProp.studiesOverrides,
//             favorites: {
//                 intervals: ['1H', '2H', '4H', '6H', '12H', '1D', '3D', '2D', '1W'],
//                 chartTypes: ['ha']
//             },
//             header_compare: false
//         };

//         return new widget(widgetOptions);
//     }

//     static defaultProps = {
//         interval: '1H',
//         containerId: 'tv_chart_container',
//         datafeedUrl: 'https://orderbook.AMEXA.exchange/api/chart',
//         libraryPath: '/charting_library/',
//         chartsStorageUrl: 'https://saveload.tradingview.com',
//         chartsStorageApiVersion: '1.1',
//         header_widget_buttons_mode: 'fullsize',
//         clientId: 'localhost',
//         fullscreen: false,
//         autosize: true,
//         studiesOverrides: {},
//         supportSymbolSearch: false,
//         compare_symbols: false,
//         disabled_features: [
//             'save_chart_properties_to_local_storage',
//             'volume_force_overlay'
//         ],
//         enabled_features: ['move_logo_to_main_pane', 'study_templates'],
//         disableSave: true
//     };

//     componentDidMount() {
//         const tvWidget = this.widgetOptionsFunc(this.props);
//         if (tvWidget) {
//             this.tvWidget = tvWidget;
//         }
//     }

//     componentWillUnmount() {
//         if (this.tvWidget) {
//             this.tvWidget.remove();
//             this.tvWidget = null;
//         }
//     }

//     render() {
//         return (
//             <div
//                 id={this.props.containerId}
//                 className={'TVChartContainer'}
//             />
//         );
//     }
// }

// function mapStateToProps(state) {
//     return {
//         theme: state.AuthReducer.switch_theme
//     };
// }

// export default connect(mapStateToProps)(TVChartContainer);


// old
// import React, { useEffect } from 'react';

// const TVChartContainer = ({ symbols = 'BTCUSD', pre_symbols = 'USDT', interval = '1' }) => {
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = 'https://s3.tradingview.com/tv.js';
//     script.async = true;
//     script.onload = () => {
//       new window.TradingView.widget({
//         container_id: 'tradingview_widget',
//         width: '100%',
//         height: '640',
//         symbol: symbols, // Use the `symbols` prop
//         interval,
//         timezone: 'Etc/UTC',
//         theme: 'dark',
//         style: '1',
//         locale: 'en',
//         toolbar_bg: '#f1f3f6',
//         enable_publishing: false,
//         hide_side_toolbar: false,
//         allow_symbol_change: true,
//         save_image: false,
//         studies: ['RSI@tv-basicstudies'], // Add RSI indicator
//         watchlist: pre_symbols ? pre_symbols.split(',') : undefined, // Handle pre_symbols as a watchlist if provided
//       });
//     };
//     document.body.appendChild(script);

//     return () => {
//       // Clean up the script if needed
//       document.body.removeChild(script);
//     };
//   }, [symbols, pre_symbols, interval]);

//   return <div id="tradingview_widget"></div>;
// };

// export default TVChartContainer;




// TradingViewWidget.jsx
// TradingViewWidget.jsx
import React, { useEffect, useRef, memo } from 'react';

function TVChartContainer({ symbols = 'BTCUSD', pre_symbols = 'USDT', interval = '1' }) {
  let pair = symbols;
pair = pair.replace('-', '').toUpperCase();
  console.log(symbols,'pair')
  const container = useRef();

  useEffect(
    () => {
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
        {
         "width": "100%",
          "height": "640",
          "symbol": "BINANCE:${pair}",
          "interval": "D",
          "timezone": "Etc/UTC",
          "theme": "dark",
          "style": "1",
          "locale": "en",
          "allow_symbol_change": true,
          "calendar": false,
          "support_host": "https://www.tradingview.com"
        }`;
      container.current.appendChild(script);
  
    },
    []
  );

  return (
    <div className="tradingview-widget-container" ref={container} style={{ height: "640px", width: "100%" }}>
      <div className="tradingview-widget-container__widget" style={{ height: "100%", width: "100%" }}></div>
      <div className="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a></div>
    </div>
  );
}

export default memo(TVChartContainer);

