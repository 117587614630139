import React, { useState } from "react";
import { Box, Typography, TextField, Button, Paper } from "@mui/material";
import { BiSend } from "react-icons/bi";

const ChatBox = () => {
  const [messages, setMessages] = useState([
    {
      sender: "user",
      text: "Hello! How are you doing?Hello! How are you doing?Hello! How are you doing?Hello! How are you doing?Hello! How are you doing?Hello! How are you doing?Hello! How are you doing?",
    },
    { sender: "me", text: "I'm good! How can I help you today?" },
    { sender: "user", text: "Hello! How are you doing?" },
    { sender: "me", text: "I'm good! How can I help you today?" },
    { sender: "user", text: "Hello! How are you doing?" },
    { sender: "me", text: "I'm good! How can I help you today?" },
    { sender: "user", text: "Hello! How are you doing?" },
    {
      sender: "me",
      text: "I'm good! How can I help you today?I'm good! How can I help you today?I'm good! How can I help you today?I'm good! How can I help you today?I'm good! How can I help you today?",
    },
    { sender: "user", text: "Hello! How are you doing?" },
    { sender: "me", text: "I'm good! How can I help you today?" },
    { sender: "user", text: "Hello! How are you doing?" },
    { sender: "me", text: "I'm good! How can I help you today?" },
    { sender: "user", text: "Hello! How are you doing?" },
    { sender: "me", text: "I'm good! How can I help you today?" },
  ]);
  const [newMessage, setNewMessage] = useState("");

  const handleSendMessage = () => {
    if (newMessage.trim() !== "") {
      setMessages([...messages, { sender: "me", text: newMessage }]);
      setNewMessage("");
    }
  };

  return (
    <Paper
      elevation={4}
      sx={{
        width: "full",
        margin: "0 auto",
        height: "600px",
        display: "flex",
        flexDirection: "column",
        background: "black",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          backgroundColor: "black",
          color: "white",
          p: 2,
          textAlign: "start",
          fontWeight: "bold",
          borderBottom: "2px solid #552dba",
          display: "flex",
        }}
      >
        <img
          src="https://thispersondoesnotexist.com/"
          alt="User"
          className="rounded-5"
          style={{ height: "40px" }}
        />
        <p className="ms-2 d-flex flex-column">
          <span>Braganza</span>
          <span>Online</span>
        </p>
      </Box>

      {/* Chat Messages */}
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",

          backgroundColor: "black",
        }}
      >
        {messages.map((msg, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: msg.sender === "me" ? "flex-end" : "flex-start",

              // mb: 2,
              p: 1,
              background: "black",
            }}
          >
            <Box
              sx={{
                maxWidth: "70%",
                p: 1.5,
                borderRadius: 2,
                backgroundColor: msg.sender === "me" ? "#6200ea" : "#818589",
              }}
            >
              <Typography variant="body1" className="text-white">
                {msg.text}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>

      {/* Input Section */}
      <Box
        sx={{
          p: 2,
          borderTop: "1px solid #e0e0e0",
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type a message..."
          sx={{
            background: "white",
            borderRadius: "20px",
          }}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
        />
        <Button
          variant="contained"
          onClick={handleSendMessage}
          endIcon={<BiSend />}
          sx={{ background: "#552dba" }}
        >
          Send
        </Button>
      </Box>
    </Paper>
  );
};

export default ChatBox;
