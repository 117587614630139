import React, { useState } from "react";
import "../modals/p2p.css";
import { BiAddToQueue, BiBook } from "react-icons/bi";
import SelectPayment from "../modals/SelectPayment";

const P2PPostAd2 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  return (
    <>
      <div className="bg-transparent container ">
        {/* Tabs */}

        {/* Tab Content */}
        <div className="tab-content modal-content">
          {/* Buy Tab Content */}
          <div className="tab-pane fade show active" id="buy" role="tabpanel">
            <div className="row mb-3">
              <section className="col-md-12">
                <label htmlFor="fixedPrice" className="form-label ">
                  Fixed
                </label>
                <p className="d-flex align-items-center p-3 border rounded">
                  <input
                    type="text"
                    className="form-control1 "
                    id="fixedPrice"
                    placeholder="85.82"
                  />
                  <span>USDT</span>
                </p>
              </section>
              <span>0 INR</span>
            </div>

            <div className=" mb-3 ">
              <label htmlFor="fixedPrice" className="form-label ">
                Order Limit
              </label>
              <section className="d-flex gap-3 justify-content-start align-items-center col-md-12">
                <p className="d-flex align-items-center p-3 border rounded w-50">
                  <input
                    type="text"
                    className="form-control1"
                    id="fixedPrice"
                    placeholder="85.82"
                  />
                  <span>INR</span>
                </p>

                <span>~</span>
                <p className="d-flex align-items-center p-3 border rounded w-50">
                  <input
                    type="text"
                    className="form-control1 "
                    id="fixedPrice"
                    placeholder="85.82"
                  />
                  <span>INR</span>
                </p>
              </section>
              <span>2.22 USDT - 222222.22 USDT</span>
            </div>

            <div className="mb-3 col-md-4 mt-4">
              <label htmlFor="paymentMethod" className="form-label">
                Payment Method
              </label>
              <h6 htmlFor="paymentMethod" className="form-label">
                Select up to 5 methods
              </h6>
              <button
                className="btn border text-white"
                style={{
                  width: "100px",
                  height: "40px",
                }}
                onClick={handleOpenModal}
              >
                <BiAddToQueue /> Add
              </button>
            </div>

            <div className="mb-3 col-md-4 mt-4">
              <label htmlFor="paymentMethod" className="form-label">
                Payment Time Limit
              </label>
              <section className="col-md-3">
                <select id="paymentMethod" className="form-select">
                  <option value="GPAY">15 MIN</option>
                  <option value="Bank">30 MIN</option>
                </select>
              </section>
            </div>
          </div>

          {/* Sell Tab Content */}
          <div className="tab-pane fade" id="sell" role="tabpanel">
            {/* Similar content for "I want to sell" */}
            <div className="row mb-3">
              <section className="col-md-4">
                <label htmlFor="fixedPrice" className="form-label ">
                  Fixed
                </label>
                <p className="d-flex p-3 border rounded">
                  <input
                    type="text"
                    className="form-control1 "
                    id="fixedPrice"
                    placeholder="85.82"
                  />
                  <span>USDT</span>
                </p>
              </section>
              <span>0 INR</span>
            </div>

            <div className=" mb-3 ">
              <label htmlFor="fixedPrice" className="form-label ">
                Order Limit
              </label>
              <section className="d-flex gap-3 justify-content-start align-items-center col-md-6">
                <p className="d-flex p-3 border rounded">
                  <input
                    type="text"
                    className="form-control1 "
                    id="fixedPrice"
                    placeholder="85.82"
                  />
                  <span>INR</span>
                </p>

                <span>~</span>
                <p className="d-flex p-3 border rounded">
                  <input
                    type="text"
                    className="form-control1 "
                    id="fixedPrice"
                    placeholder="85.82"
                  />
                  <span>INR</span>
                </p>
              </section>
              <span>2.22 USDT - 222222.22 USDT</span>
            </div>

            <div className="mb-3 col-md-4 mt-4">
              <label htmlFor="paymentMethod" className="form-label">
                Payment Method
              </label>
              <h6 htmlFor="paymentMethod" className="form-label">
                Select up to 5 methods
              </h6>
              <button
                className="btn border text-white"
                style={{
                  width: "100px",
                  height: "40px",
                }}
              >
                <BiAddToQueue /> Add
              </button>
            </div>

            <div className="mb-3 col-md-4 mt-4">
              <label htmlFor="paymentMethod" className="form-label">
                Payment Time Limit
              </label>
              <section className="col-md-3">
                <select id="paymentMethod" className="form-select">
                  <option value="GPAY">15 MIN</option>
                  <option value="Bank">30 MIN</option>
                </select>
              </section>
            </div>
          </div>
        </div>

        {/* Help and Support */}
        <label className="d-flex gap-2 px-3 py-2">
          <BiBook size={25} />
          <span>Help & Support</span>
        </label>
      </div>
      <SelectPayment
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Select payment method"
      />
    </>
  );
};

export default P2PPostAd2;
