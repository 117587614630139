import React from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

export default function StartTrade() {
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  AOS.init({ duration: 1000 });
  return (
    <>
      <div className="get_started_section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center text-md-start">
              <div className="para_heading">
                Get Started in
                <br />
                <span className="text-gradient">3 Minutes</span>
              </div>
              <div className="sub_heading my-4">
                Discover the most intuitive and unparalleled user
                <br /> experience with Amexa
              </div>
              <div className="mt-4 mb-3">
                <a href="/#trade_now">
                  <button className="login_btn">Learn More</button>
                </a>
              </div>
            </div>
            <div className="col-md-6 ps-5 mt-2">
              <div className="link_card">
                <div>How to Buy Crypto With AMEXA</div>
                <div>
                  <img src="/img/arrow-up.png" />
                </div>
              </div>
              <div className="link_card">
                <div>How to Withdraw and Deposit on AMEXA</div>
                <div>
                  <img src="/img/arrow-up.png" />
                </div>
              </div>
              <div className="link_card">
                <div>How to Start Wallet on AMEXA</div>
                <div>
                  <img src="/img/arrow-up.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="crypto_trading_info_section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="para_heading text-center">
                Your One-Stop Solution for Crypto and Forex, <br />
                <span className="text-gradient">Only with Amexa.</span>
              </div>
              <div className="subheading mt-4 text-center">
                Discover seamless trading across crypto and forex markets with a
                platform designed for traders of all kinds. A platform for
                Traders, Cooked by Traders.
              </div>
              <div className="crypto_trading_info_div">
                <div className="para_heading text-center">
                  Start your Cryto Trading in a<br /> minutes with AMEXA
                </div>
                <div>
                  {isLoggedIn ? (
                    <div className="d-flex justify-content-center">
                      <a href="/#trade_now">
                        <button className="crypto_trading_info_btn2">
                          Trade Now
                        </button>
                      </a>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <NavLink to="/login">
                        <button className="crypto_trading_info_btn1">
                          Login
                        </button>
                      </NavLink>
                      <NavLink to="/create">
                        <button className="crypto_trading_info_btn2">
                          Get Started
                        </button>
                      </NavLink>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
