import React from "react";
import "../modals/p2p.css";
import { BiBook, BiMinus, BiPlus } from "react-icons/bi";
import SelectWithImages from "./SelectWithImages";

const P2PPostAd = () => {
  const assetsOptions = [
    {
      iconUrl: "https://cryptologos.cc/logos/bitcoin-btc-logo.png",
      currencySymbol: "BTC",
    },
    {
      iconUrl: "https://cryptologos.cc/logos/usd-coin-usdc-logo.png",
      currencySymbol: "USDC",
    },
    {
      iconUrl: "https://cryptologos.cc/logos/bitcoin-btc-logo.png",
      currencySymbol: "BTC",
    },
    {
      iconUrl: "https://cryptologos.cc/logos/usd-coin-usdc-logo.png",
      currencySymbol: "USDC",
    },
    {
      iconUrl: "https://cryptologos.cc/logos/bitcoin-btc-logo.png",
      currencySymbol: "BTC",
    },
    {
      iconUrl: "https://cryptologos.cc/logos/usd-coin-usdc-logo.png",
      currencySymbol: "USDC",
    },
  ];
  const fiatOptions = [
    {
      currencyCode: "AFN",
      currencySymbol: "؋",
      currencyScale: 2,
      countryCode: "AF",
      iconUrl:
        "https://bin.bnbstatic.com/image/admin_mgs_image_upload/20220318/02b35efa-42fe-4f69-993d-f94711a57efd.png",
    },

    {
      currencyCode: "EUR",
      currencySymbol: "€",
      currencyScale: 3,
      countryCode: "EU",
      iconUrl: "https://bin.bnbstatic.com/image/c2c/currency-icons/EUR.png",
    },

    {
      currencyCode: "AUD",
      currencySymbol: "A$",
      currencyScale: 3,
      countryCode: "AU",
      iconUrl: "https://bin.bnbstatic.com/image/c2c/currency-icons/AUD.png",
    },
    {
      currencyCode: "CAD",
      currencySymbol: "C$",
      currencyScale: 3,
      countryCode: "CA",
      iconUrl: "https://bin.bnbstatic.com/image/c2c/currency-icons/CAD.png",
    },

    {
      currencyCode: "INR",
      currencySymbol: "₹",
      currencyScale: 2,
      countryCode: "IN",
      iconUrl: "https://bin.bnbstatic.com/image/c2c/currency-icons/INR.png",
    },
    {
      currencyCode: "IDR",
      currencySymbol: "Rp",
      currencyScale: 2,
      countryCode: "ID",
      iconUrl: "https://bin.bnbstatic.com/image/c2c/currency-icons/IDR.png",
    },

    {
      currencyCode: "USD",
      currencySymbol: "$",
      currencyScale: 2,
      countryCode: "US",
      iconUrl: "https://bin.bnbstatic.com/image/c2c/currency-icons/USD.png",
    },
  ];

  return (
    <>
      <div className="bg-transparent container">
        {/* Tabs */}

        {/* Tab Content */}
        <div className="tab-content modal-content">
          {/* Buy Tab Content */}
          <div className="tab-pane fade show active" id="buy" role="tabpanel">
            <div className="row">
              <div className="mb-4 col-md-6">
                <label htmlFor="asset" className="form-label">
                  Asset
                </label>
                <SelectWithImages items={assetsOptions} />
              </div>
              <div className="mb-4 col-md-6">
                <label htmlFor="fiat" className="form-label">
                  With Fiat
                </label>
                <SelectWithImages items={fiatOptions} />
              </div>
            </div>

            <div className="mb-4">
              <label className="form-label">Price Type</label>
              <div className="d-flex gap-5">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="priceType"
                    id="fixed"
                  />
                  <label className="form-check-label" htmlFor="fixed">
                    Fixed
                  </label>
                </div>
                {/* <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="priceType"
                    id="floating"
                  />
                  <label className="form-check-label" htmlFor="floating">
                    Floating
                  </label>
                </div> */}
              </div>
            </div>

            <div className="mb-3 col-md-12">
              <label htmlFor="fixedPrice" className="form-label ">
                Fixed
              </label>
              <section className="d-flex border rounded p-2 justify-content-between align-items-center">
                <BiPlus />
                <input
                  type="text"
                  className="form-control1"
                  id="fixedPrice"
                  placeholder="85.82"
                />
                <BiMinus />
              </section>
              <div className="d-flex justify-content-between border-bottom pb-2">
                <small>Your Price should be between ₹85.82 - 87.56</small>
              </div>
            </div>

            <div className="d-flex justify-content-between w-50 mt-5">
              <section>
                <span>YOUR PRICE</span>
                <h5>85.64</h5>
              </section>
              <section>
                <span>HIGHEST ORDER PRICE</span>
                <h5>89.64</h5>
              </section>
            </div>

            {/* <div className="mb-3 col-md-4 mt-4">
              <label htmlFor="paymentMethod" className="form-label">
                Select Payment Method
              </label>
              <select id="paymentMethod" className="form-select">
                <option value="GPAY">GPAY</option>
                <option value="Bank">Bank</option>
              </select>
            </div> */}
          </div>

          {/* Sell Tab Content */}
          <div className="tab-pane fade" id="sell" role="tabpanel">
            {/* Similar content for "I want to sell" */}
            <div className="row">
              <div className="mb-3 col-md-4">
                <label htmlFor="asset" className="form-label">
                  Asset
                </label>
                <SelectWithImages />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="fiat" className="form-label">
                  With Fiat
                </label>
                <SelectWithImages />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Price Type</label>
              <div className="d-flex gap-5">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="priceType"
                    id="fixed"
                  />
                  <label className="form-check-label" htmlFor="fixed">
                    Fixed
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="priceType"
                    id="floating"
                  />
                  <label className="form-check-label" htmlFor="floating">
                    Floating
                  </label>
                </div>
              </div>
            </div>

            <div className="mb-3 col-md-4">
              <label htmlFor="fixedPrice" className="form-label ">
                Fixed
              </label>
              <section className="d-flex border rounded p-2 justify-content-between align-items-center">
                <BiPlus />
                <input
                  type="text"
                  className="form-control1"
                  id="fixedPrice"
                  placeholder="85.82"
                />
                <BiMinus />
              </section>
            </div>

            <div className="d-flex justify-content-between border-bottom pb-2">
              <span>Your Price should be between ₹85.82 - 87.56</span>
            </div>

            <div className="d-flex justify-content-between w-50 mt-5">
              <section>
                <span>YOUR PRICE</span>
                <h2>85.64</h2>
              </section>
              <section>
                <span>HIGHEST ORDER PRICE</span>
                <h2>89.64</h2>
              </section>
            </div>
          </div>
        </div>

        {/* Help and Support */}
        <label className="d-flex gap-2 px-3 py-2">
          <BiBook size={25} />
          <span>Help & Support</span>
        </label>
      </div>
    </>
  );
};

export default P2PPostAd;
