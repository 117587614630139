import React, { useState, useEffect, useRef } from "react";

const TermsTags = [
  {
    checked: false,
    label: "ABC",
    desc: "Its a abc",
  },
  {
    checked: false,
    label: "CDE",
    desc: "Its a cde",
  },
  {
    checked: false,
    label: "EFG",
    desc: "Its a efg",
  },
  {
    checked: false,
    label: "HIJ",
    desc: "Its a hij",
  },
];

function SwapAssetsDropdown() {
  const [termsTagsToShow, setTermsTagsToShow] = useState(TermsTags);
  const [searchAssets, setSearchAssets] = useState(termsTagsToShow);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Handle clicking outside of dropdown to close it
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSearch = (value) => {
    setSearchAssets(
      termsTagsToShow.filter((item) =>
        item.label?.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const checkOrUncheck = (tag) => {
    setTermsTagsToShow((prev) =>
      prev.map((item) => {
        if (item.label === tag.label) {
          return {
            ...item,
            checked: !item.checked,
          };
        } else {
          return item;
        }
      })
    );
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button
        className="form-control bg-transparent mt-2 d-flex justify-content-between align-items-center text-dark dropdown-toggle"
        onClick={toggleDropdown}
        type="button"
        aria-expanded={isOpen}
      >
        <div className="bg-transparent">
          <span className="btn btn-modal bg-transparent text-dark">
            {termsTagsToShow
              ?.filter(({ checked }) => checked)
              .map(({ label }) => label)
              .join(",") || "Add Tags"}
          </span>
        </div>
      </button>

      <div className={`dropdown-menu w-100 ${isOpen ? "show" : ""}`}>
        <div className="tokensearch my-2 mx-2">
          <input
            type="search"
            value={search}
            onChange={(e) => {
              handleSearch(e.target.value);
              setSearch(e.target.value);
            }}
            className="form-control"
            placeholder="Search name or paste address"
          />
        </div>
        <div className="popular-tokens">
          <div className="token-list">
            {search && searchAssets.length === 0 ? (
              <h6 className="text-danger text-center">No Asset Found!</h6>
            ) : (
              (search ? searchAssets : termsTagsToShow).map((item, i) => (
                <button
                  key={i}
                  className="dropdown-item"
                  onClick={() => {
                    checkOrUncheck(item);
                    setSearch("");
                    setIsOpen(false);
                  }}
                >
                  <div className="tokenitems d-flex gap-2">
                    <div className="token-image">
                      {/* {item.checked ? <>checked</> : <>unchecked</>} */}
                      <input type="checkbox" value={item.checked} />
                    </div>
                    <div className="token-name">
                      <h6 className="mb-1">{item?.label}</h6>
                      <div className="d-flex justify-content-between">
                        <span
                          style={{
                            maxWidth: "200px",
                            overflow: "hidden",
                          }}
                        >
                          {item?.desc}
                        </span>
                      </div>
                    </div>
                  </div>
                </button>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SwapAssetsDropdown;
