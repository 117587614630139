import React from "react";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { MdVerified } from "react-icons/md";

const CreateOrder3 = () => {
  return (
    <>
      <div className="d-flex flex-column gap-4 align-items-center justify-content-center col-12">
        <div className="d-flex align-items-center flex-column back-borders p-3 col-lg-6 gap-3">
          <MdVerified size={100} className="text-green bounce_1" />
          <h2>100 USDT</h2>
          <h4>Deposited into your Account</h4>
          {/* <Link to="">Check Wallet</Link> */}
          <button className="btn btn-outline-light px-4  d-flex justify-content-between align-items-center gap-3">
            <span>Check Wallet</span>
          </button>
          <p>Review CounterParty</p>
          <section className="d-flex gap-3">
            <button className="btn btn-outline-light px-4 d-flex justify-content-between align-items-center gap-3">
              <FaThumbsUp /> <span>Positive</span>
            </button>
            <button className="btn btn-outline-light px-4  d-flex justify-content-between align-items-center gap-3">
              <FaThumbsDown />
              <span>Negative</span>
            </button>
          </section>
        </div>
        <h6>Rate Your P2P Experience</h6>
      </div>
    </>
  );
};

export default CreateOrder3;
