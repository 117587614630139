import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import AssetSidebar from "./components/AssetSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { FaCoins, FaSortDown, FaSortUp } from "react-icons/fa";
import Stepper from "./components/stepper";
import {
  N_GetAllSymbol,
  N_createAddress,
  N_getBlockchain,
  N_getBlockchainData,
  N_depositHistory,
  N_getAllBlockchain,
} from "./redux/helpers/api_functions_new";
import AssetOption from "./components/AssetsDropdown";
import formatDate from "../hooks/formatDate";

export default function AssetsDeposit() {
  const history = useHistory();
  const [blockchain, setBlockchain] = useState();
  const [currency, setCurrency] = useState();
  const [contract, setContract] = useState();
  const [coindata, setCoinData] = useState();
  const [wallets, setWallets] = useState();
  const [deposithistory, setDepositHistory] = useState();
  const [symbolData, setSymbolData] = useState();
  const [isSelected, setIsSelected] = useState(0);
  const [search, setSearch] = useState("");
  const { user } = useSelector((state) => state.AuthReducer);
  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    NotificationManager.success("Copied...");
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    N_getBlockchain().then((res) => {
      if (res.status == 200) {
        setBlockchain(res.data);
      }
    });
    N_GetAllSymbol().then((res) => {
      setWallets(res);
      const coinsData = res.find((item) => item.symbol == "ETH");
      setCoinData(coinsData);
    });
  }, []);

  useEffect(() => {
    if (coindata) {
      let data = {
        user_id: user?.params ? user.params.user_id : user.user_id,
        symbol: coindata.symbol,
      };
      // N_getAllBlockchain(coindata.symbol).then((res) => {
      //   if (res.status == 200) {
      //     setBlockchain(res.data);
      //   }
      // });
      N_depositHistory(data)
        .then((data) => {
          if (data.status === 200) {
            setDepositHistory(data?.result);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [coindata]);
  const walletsdata =
    wallets &&
    wallets.filter((product) => {
      return product.symbol.includes(search);
    });
  const onSelect = (item) => {
    setCoinData(item);
    setIsSelected(false);
    setSymbolData("");
  };

  const onNetworkSelect = (item) => {
    N_createAddress(
      user?.params ? user.params.user_id : user.user_id,
      coindata?.symbol
    );
    setSymbolData(item);
    N_getBlockchainData(
      item?.blockchain,
      user?.params ? user.params.user_id : user.user_id,
      coindata?.symbol
    ).then((res) => {
      if (res.status == 200) {
        if (res.currencydata) {
          setCurrency(res.currencydata);
          setContract(res.contractdata);
          setIsSelected(1);
          // } else if (
          //   item.blockchain == coindata?.blockchain
          // ) {
          //   setCurrency(res.currencydata);
          //   setIsSelected(2);
          // } else {
        } else {
          setIsSelected(3);
        }
      }
    });
  };
  return (
    <>
      <Header />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="container">
            <div className="marginTop">
              <div className="account_page">
                <div className="flex_row mb-4">
                  <div className="d-flex gap-2 align-items-center">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fas fa-arrow-left "></i>
                    </span>{" "}
                    <h3 className="mb-0"> Deposit Crypto </h3>
                  </div>

                  {/* <div>
                      <a href="/AssetsFiat" className="btn btn-light">
                        Deposit Fiat{" "}
                        <i class="fa-solid fa-arrow-right-long ms-1"></i>
                      </a>
                    </div> */}
                </div>

                <div className="row">
                  <div className="col-lg-7">
                    <div className="cstm_form">
                      <div class="row_flex_div ">
                        <div class="col_left"></div>
                        <div class="col_right">
                          <label for="inputPassword6" class="">
                            Coin
                          </label>
                        </div>
                      </div>

                      <div class=" mb-4">
                        <div class="text-lgray">
                          <label for="inputPassword6" class="mb-2">
                            Select Coin
                          </label>
                        </div>
                        <AssetOption
                          walletsdata={walletsdata}
                          coindata={coindata}
                          onSelect={onSelect}
                        />
                        {/* <div class="col_right">
                          <div className="select_box">
                            {" "}
                            <div
                              className="select_box_inner"
                              data-bs-toggle="modal"
                              data-bs-target="#coin_modal"
                            >
                              <div class="d-flex align-items-center">
                                <div>
                                  <img
                                    src={coindata?.icon}
                                    alt="coin symbole"
                                    class="coin_img me-2"
                                  />
                                </div>
                                <div class="product_name">
                                  {coindata?.symbol}{" "}
                                  <span className="unit text-muted">
                                    {coindata?.name}
                                  </span>
                                </div>{" "}
                              </div>
                            </div>
                            <div>
                              {" "}
                              <img
                                src="https://assets.staticimg.com/kucoin-main-web/2.0.20/svg/arrow.267b17d2.svg"
                                class="arrow_img"
                                alt="arrow-icon"
                              />
                            </div>
                          </div>
                        </div> */}
                        <p class="fs-14 mt-3 ms-3">
                          {wallets &&
                            wallets.map((item) => {
                              if (
                                item.symbol == "USDT" ||
                                item.symbol == "ETH" ||
                                item.symbol == "BNB" ||
                                item.symbol == "TRX" ||
                                item.symbol == "BTC"
                              ) {
                                return (
                                  <span
                                    style={{ cursor: "pointer" }}
                                    class="me-3 "
                                    onClick={() => {
                                      setCoinData(item);
                                      setIsSelected(false);
                                      setSymbolData();
                                    }}
                                  >
                                    <img
                                      src={item.icon}
                                      alt="coin symbole"
                                      class="market_coin_img "
                                      style={{ width: "24px", height: "24px" }}
                                    />{" "}
                                    <span style={{ marginTop: "4px" }}>
                                      {item.symbol}
                                    </span>
                                  </span>
                                );
                              }
                            })}
                        </p>
                      </div>
                      <div class="row_flex_div">
                        <div class="col_left"></div>
                        <div class="col_right">
                          <label for="inputPassword6" class="">
                            Network
                          </label>
                        </div>
                      </div>
                      <div class=" mb-4">
                        <div class="text-lgray mb-2">
                          <label for="inputPassword6" class="form-label">
                            Select Network
                          </label>
                        </div>
                        <AssetOption
                          walletsdata={blockchain}
                          coindata={symbolData}
                          onSelect={onNetworkSelect}
                        />
                        {/* <div class="col_right">
                          <div className="select_box">
                            {" "}
                            <div className="select_box_inner">
                              <div
                                className="select_box_inner"
                                data-bs-toggle="modal"
                                data-bs-target="#network_modal"
                              >
                                <div class="product_name">
                                  {symbolData
                                    ? symbolData.symbol +
                                    " " +
                                    symbolData.blockchain
                                    : "Select Network"}
                                </div>{" "}
                              </div>
                            </div>
                            <div>
                              {" "}
                              <img
                                src="https://assets.staticimg.com/kucoin-main-web/2.0.20/svg/arrow.267b17d2.svg"
                                class="arrow_img"
                                alt="arrow-icon"
                              />
                            </div>
                          </div>
                        </div> */}
                      </div>

                      {isSelected == 1 && coindata?.is_deposite == 1 ? (
                        <div class=" mb-4">
                          <div class="col_left"></div>
                          <div class="col_right">
                            <h5>
                              <small className="text-lgray">Address Info</small>
                            </h5>
                            {currency != null ? (
                              <div className="d-flex gap-3 align-items-center jambotron mx-1">
                                <div
                                  style={{ width: "140px" }}
                                  className=" p-2 shadow"
                                >
                                  <div className="card-body">
                                    <img
                                      alt="qr code"
                                      src={`https://api.qrserver.com/v1/create-qr-code/?data=${currency?.wallet_address}&amp;size=30x30`}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <div className="text-lgray mb-1">
                                    Deposit Address
                                  </div>
                                  <h5>
                                    <div className="d-flex gap-2 align-items-center">
                                      <div
                                        className="text-container"
                                        style={{
                                          maxWidth: "75%",
                                          wordBreak: "break-word",
                                          overflowWrap: "break-word",
                                        }}
                                      >
                                        {currency == null
                                          ? "0X000000XX0000000000000000XX00000000000"
                                          : currency?.wallet_address}
                                      </div>
                                      <div
                                        onClick={() =>
                                          copyText(currency?.wallet_address)
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <i className="fa fa-copy ms-2"></i>
                                      </div>
                                    </div>
                                  </h5>
                                </div>
                              </div>
                            ) : (
                              <div className="d-flex gap-4 align-items-center flex-column jambotron">
                                <div>
                                  <i
                                    class="fas fa-exclamation-circle"
                                    style={{ fontSize: "24px" }}
                                  ></i>
                                </div>
                                <div>Note:</div>
                                <p className="text-center">
                                  You haven't applied for the deposit address
                                  for ETH-Optimism yet. Click the button below
                                  to get an address.
                                </p>
                                {/* <button className="btn btn-pink"
                                 onClick={() => {
                                  N_createAddress(
                                    user?.params ? user.params.user_id : user.user_id,
                                    coindata?.symbol
                                  );
                                  setSymbolData(item);
                                  N_getBlockchainData(
                                    item?.blockchain,
                                    user?.params ? user.params.user_id : user.user_id,
                                    coindata?.symbol
                                  ).then((res) => {
                                    if (res.status == 200) {
                                      if (res.contractdata) {
                                        setCurrency(res.currencydata);
                                        setContract(res.contractdata);
                                        setIsSelected(1);
                                        // } else if (
                                        //   item.blockchain == coindata?.blockchain
                                        // ) {
                                        //   setCurrency(res.currencydata);
                                        //   setIsSelected(2);
                                        // } else {
                                      } else {
                                        setIsSelected(3);
                                      }
                                    }
                                  });
                                }}
                                >Get Address</button> */}
                              </div>
                            )}

                            <div className="row">
                              {/* <div className="col-3 m-auto">
                                <div className="custom_card p-2 shadow">
                                  <div className="card-body">
                                  <a href="">
                                  {" "}
                                  <img
                                    src={`https://api.qrserver.com/v1/create-qr-code/?data=${currency?.wallet_address}&amp;size=50x50`}
                                  />
                                </a>
                                    
                                  </div>
                                </div>
                                
                              </div> */}
                            </div>

                            {/* <div className="mt-4">
                        <small className="text-lgray">Recipient Account</small>
                        <p>Funding Account (Main)</p>
                      </div> */}
                            {/* <div className="mt-4">
                        <div className="d-flex justify-content-between">
                          <div>
                            <small className="text-lgray">
                              Deposit Confirmation
                            </small>
                            <p>
                              3 <span className="text-lgray">Block(s)</span>{" "}
                            </p>
                          </div>
                          <div>
                            <small className="text-lgray">
                              Withdrawal Confirmation
                            </small>
                            <p>
                              3 <span className="text-lgray">Block(s)</span>{" "}
                            </p>
                          </div>
                        </div>
                      </div> */}
                            <div className="border-bottom mt-4 "></div>
                            {isSelected == 1 && coindata?.is_deposite == 1 ? (
                              <>
                                <p className=" mt-4">
                                  The deposit will be credited and available for
                                  trading after about 6 confirmation(s).
                                </p>
                                <p className=" mt-2">
                                  The deposit will be unlocked and available for
                                  withdrawal after about 12 confirmation(s).
                                </p>
                                <p className=" mt-2">
                                  Send only {symbolData?.symbol} to this deposit
                                  address. Other assets will be lost and cannot
                                  be retrieved.
                                </p>
                                {contract?.contract_address && (
                                  <div className="mt-2">
                                    <div>
                                      <small className="text-lgray">
                                        Contract Address
                                      </small>
                                      <p>
                                        {contract?.contract_address}{" "}
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            copyText(contract?.contract_address)
                                          }
                                          className=""
                                        >
                                          <i className="fa fa-copy ms-2"></i>
                                        </span>{" "}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                <p className="text-lgray fs-12">
                                  Confirm that your network is{" "}
                                  <span className="text-primary">
                                    {currency?.blockchain}
                                  </span>
                                  .
                                </p>
                              </>
                            ) : null}
                          </div>
                        </div>
                      ) : coindata?.is_deposite == 0 ? (
                        <div class=" mb-4">
                          <div class="col_left"></div>
                          <div class="col_right jambotron">
                            <h5>
                              <small className="text-lgray">
                                Deposits are temporarily unavailable. Any
                                pending deposit orders will be processed as soon
                                as possible after deposits come back online.
                              </small>
                            </h5>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-1"></div>
                  <div className="col-lg-4">
                    <h5>FAQs</h5>

                    <div class="lrtcss-ifarjv">
                      <p class="d-flex">
                        <div>
                          {" "}
                          <i class="fa-regular fa-rectangle-list me-2 fs-12 text-lgray"></i>
                        </div>
                        <div>
                          {" "}
                          <a class="text-lgray" href="#">
                            {" "}
                            How do I deposit crypto into my Aconomic account?{" "}
                          </a>
                        </div>
                      </p>
                      <p class="d-flex">
                        <div>
                          {" "}
                          <i class="fa-regular fa-rectangle-list me-2 text-lgray fs-12"></i>
                        </div>
                        <div>
                          {" "}
                          <a class="text-lgray" href="#">
                            {" "}
                            What should I do if I didn't receive my deposits or
                            I deposit to an incorrect address?{" "}
                          </a>
                        </div>
                      </p>
                      <p class="d-flex">
                        <div>
                          {" "}
                          <i class="fa-regular fa-rectangle-list me-2 text-lgray fs-12"></i>
                        </div>
                        <div>
                          {" "}
                          <a class="text-lgray" href="#">
                            What should I do if I deposit the wrong crypto?{" "}
                          </a>
                        </div>
                      </p>
                      <p class="d-flex">
                        <div>
                          {" "}
                          <i class="fa-regular fa-rectangle-list me-2 text-lgray fs-12"></i>
                        </div>
                        <div>
                          {" "}
                          <a class="text-lgray" href="#">
                            What should I do if I forgot to specify the Memo,
                            Tag, or Message for my deposit?
                          </a>
                        </div>
                      </p>
                      <p class="d-flex">
                        <div>
                          {" "}
                          <i class="fa-regular fa-rectangle-list me-2 text-lgray fs-12"></i>
                        </div>
                        <div>
                          {" "}
                          <a class="text-lgray" href="#">
                            {" "}
                            What should I do if I mistakenly deposit through the
                            BSC or BEP20 network and did not receive the
                            deposit?
                          </a>
                        </div>
                      </p>
                      <p class="d-flex">
                        <div>
                          {" "}
                          <i class="fa-regular fa-rectangle-list me-2 text-lgray fs-12"></i>
                        </div>
                        <div>
                          {" "}
                          <a class="text-lgray" href="#">
                            What are the common deposit networks?
                          </a>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="flex_row mb-4">
                    <div className="">
                      <div class="">
                        <h3 className=""> Deposit History </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="overflow-auto mt-3">
                  <div className="table_scroll_div">
                    <table className="table global_table">
                      <thead>
                        <tr>
                          <th className="text-start">
                            <div className="d-flex align-items-center">
                              <span>Time</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>
                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-start">
                            <div className="d-flex align-items-center">
                              <span>Symbol</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>
                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-start">
                            <div className="d-flex align-items-center">
                              <span>Amount</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>
                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-start">
                            <div className="d-flex align-items-center">
                              <span>Address</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>
                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-start">
                            <div className="d-flex align-items-center">
                              <span>Status</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>
                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-start">
                            <div className="d-flex align-items-center">
                              <span>Action</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {deposithistory && deposithistory.length > 0 ? (
                          deposithistory.map((item) => (
                            <tr key={item.id}>
                              <td className="text-start">
                                {formatDate(item.createdAt)}
                              </td>
                              <td className="text-start">{item?.symbol}</td>
                              <td className="text-start">
                                {Number(item.amount)?.toFixed(6)}
                              </td>
                              <td className="text-start">
                                {item.to_address
                                  ? `${item.to_address.slice(
                                      0,
                                      6
                                    )}...${item.to_address.slice(-6)}`
                                  : ""}
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => copyText(item.to_address)}
                                  className=""
                                >
                                  <i className="fa fa-copy ms-2"></i>
                                </span>
                              </td>
                              <td className="text-start text-green">
                                Completed
                              </td>
                              <td>
                                <button
                                  className="detail_btn py-1"
                                  onClick={() => {
                                    openModal();
                                    setModalData(item);
                                  }}
                                >
                                  Details
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              <img
                                src="/img/no-data.png"
                                className="no-data-found"
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </main>
      </div>

      <div
        class="modal coin_modal fade networkModal"
        tabindex="-1"
        id="coin_modal"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">
              <h4 class="modal-title">Coin</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="m-1">
                <div class="input-group ">
                  <span class="input-group-text bg-transparent">
                    <div class="">
                      <i class="fa fa-search text-lgray"></i>
                    </div>
                  </span>
                  <input
                    type="text"
                    class="form-control text-start mp-exide-form bg-white"
                    aria-label="Text input with checkbox"
                    placeholder="Search "
                    onChange={(e) => {
                      setSearch(e.target.value.toUpperCase());
                    }}
                  />
                </div>
              </div>

              <div className="mt-4">
                <h6 className="text-lgray">Popular</h6>
                <p class="fs-14">
                  {wallets &&
                    wallets.map((item) => {
                      if (
                        item.symbol == "USDT" ||
                        item.symbol == "ETH" ||
                        item.symbol == "BNB" ||
                        item.symbol == "TRX" ||
                        item.symbol == "BTC"
                      ) {
                        return (
                          <span
                            style={{ cursor: "pointer", marginRight: "8px" }}
                            class=" me-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                              setCoinData(item);
                              setIsSelected(false);
                              setSymbolData();
                            }}
                          >
                            <img
                              src={item.icon}
                              alt="coin symbole"
                              class="market_coin_img"
                            />{" "}
                            {item.symbol}
                          </span>
                        );
                      }
                    })}
                </p>
              </div>

              <div className="custom_scrollbar height250">
                {walletsdata &&
                  walletsdata.map((item) => {
                    if (item.symbol !== "INR") {
                      return (
                        <div
                          class="select_options"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            setCoinData(item);
                            setIsSelected(false);
                            setSymbolData("");
                          }}
                        >
                          <div>
                            <img
                              src={item.icon}
                              alt="coin symbole"
                              class="market_coin_img"
                            />
                          </div>
                          <div class="product_name">
                            <div class="mb-0 fw-bold">{item.symbol}</div>
                            <div class="text-muted fs-12">{item.name}</div>
                          </div>{" "}
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal coin_modal fade" tabindex="-1" id="network_modal">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">
              <h4 class="modal-title">Select Network</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="custom_scrollbar height275">
                {blockchain &&
                  blockchain.map((item) => {
                    return (
                      <div
                        class="select_options"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          N_createAddress(
                            user?.params ? user.params.user_id : user.user_id,
                            coindata?.symbol
                          );
                          setSymbolData(item);
                          N_getBlockchainData(
                            item?.blockchain,
                            user?.params ? user.params.user_id : user.user_id,
                            coindata?.symbol
                          ).then((res) => {
                            if (res.status == 200) {
                              if (res.currencydata) {
                                setCurrency(res.currencydata);
                                setContract(res.contractdata);
                                setIsSelected(1);
                                // } else if (
                                //   item.blockchain == coindata?.blockchain
                                // ) {
                                //   setCurrency(res.currencydata);
                                //   setIsSelected(2);
                                // } else {
                              } else {
                                setIsSelected(3);
                              }
                            }
                          });
                        }}
                      >
                        <div class="product_name d-flex gap-2 align-item-center">
                          <div class="mb-0 fw-bold ">
                            {" "}
                            {item.symbol}{" "}
                            {item.type == "erc20"
                              ? "(ERC20)"
                              : item.type == "trc20"
                              ? "(TRC20)"
                              : item.type == "bep20"
                              ? " Smart Chain(BEP20)"
                              : item.type == "grc20"
                              ? "(GRC20)"
                              : ""}
                          </div>
                          <div class="text-muted fs-12"> {item.blockchain}</div>
                        </div>{" "}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal */}
      <div
        className={`modal fade no-scroll bg-blur ${
          isModalOpen ? "show d-block" : ""
        }`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="paymentmodal2"
        aria-hidden={!isModalOpen}
        style={{ display: isModalOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content first_registration_section comming-soon-bg p-2">
            <div className="modal-body text-white">
              <div className="d-flex justify-content-between align-items-center">
                <h5>Details</h5>

                <div
                  style={{ textAlign: "end", fontSize: "22px" }}
                  type="button"
                  className="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setIsModalOpen(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </div>
              </div>

              <div className="my-2 d-flex gap-3 align-items-center">
              </div>
              <div className="row ">
              <div className="col-4 mb-2">
                  <div className="text-lgray">Symbol</div>
                  <div>{modalData?.symbol?.toUpperCase()}</div>
                </div>

                <div className="col-4 mb-2">
                  <div className="text-lgray">Address</div>
                  <div>
                    {" "}
                    {modalData.to_address
                      ? `${modalData.to_address.slice(
                          0,
                          6
                        )}...${modalData.to_address.slice(-6)}`
                      : ""}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => copyText(modalData.to_address)}
                      className=""
                    >
                      <i className="fa fa-copy ms-2"></i>
                    </span>
                  </div>
                </div>

                <div className="col-4 mb-2">
                  <div className="text-lgray">Status</div>
                  <div>Success (100%)</div>
                </div>

                <div className="col-4 mb-2">
                  <div className="text-lgray">Update Time</div>
                  <div>{new Date(modalData.createdAt).toLocaleString()}</div>
                </div>

                <div className="col-4 mb-2">
                  <div className="text-lgray">Total Amount</div>
                  <div>{Number(modalData?.amount)?.toFixed(6)}</div>
                </div>

                <div className="col-4 mb-2">
                  <div className="text-lgray">Txn. Id</div>
                  <div>
                    {" "}
                    {modalData.tx_id
                      ? `${modalData.tx_id.slice(
                          0,
                          6
                        )}...${modalData.tx_id.slice(-6)}`
                      : ""}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => copyText(modalData.tx_id)}
                      className=""
                    >
                      <i className="fa fa-copy ms-2"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* end modal  */}
    </>
  );
}
