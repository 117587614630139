import React,{useEffect} from 'react'
import Header from "./Header";
import Footer from './Footer';



export default function Privacy(props) {
    useEffect(() => {
        document.title = "AMEXA Privacy policy"
    }, [])

    return (
        <>
            <Header {...props} />
            <div className="wrap-privacy d-flex align-items-center ">
            <div class="container py-5">
            <div className="d-block mx-auto">
                    <h1 className="text-left font-size-40">Privacy Policy</h1><br />
                    </div>
        <p class="para">The website and mobile app operated under the brand name – “AMEXA” is owned by Bit Cipher Labs LLP (“We”, “Us”, “LLP”, or “Our”, which expression shall mean and include its affiliates, successors, and assigns). This policy describes our policies and procedures for the collection, use, storage, disclosure, and protection of any personal information, including but not limited to, business or personal information provided by you (“you”) while using the platform.</p>
        <p class="para">This policy constitutes a legal agreement between you, as the user of the platform, and us, as the owner of the platform. By voluntarily providing us or allowing us to access your personal information, you consent to our use of it in accordance with this policy. By merely visiting or accessing the platform, you expressly consent to LLP’s use and disclosure of your personal information in accordance with this privacy policy. This policy does not apply to third-party platforms linked to the platform. If you do not agree with the terms and conditions of this policy, please do not use or access this platform. In the absence of acceptance of this policy by you, we will not be able to provide services to you.</p>
        <p class="para">Your use of the platform will be governed by this policy in addition to the terms of use applicable to you. This policy has been drafted in accordance with the Information Technology Act, 2000, and the data protection rules and regulations of India.</p>

        <h2>Information Collected</h2>
        <p class="para">We will use the information provided by you only in accordance with the purposes described in this policy and the AML policy. We collect your personal information or non-personal information (as defined hereinafter) when you use the platform and at the time of your registration with the platform, i.e., creating an account on the platform to avail our services. Further, we collect information from you during the performance of services for you. We collect information such as your name, email, address, IP address, zip code, location, device ID, and device location. We collect all of this information to provide you with the aforementioned financial and brokerage services.</p>
        <p class="para">Except as provided herein or as clearly disclosed to you at the time of collection, we do not solicit any sensitive information about you. However, if you share such information with us voluntarily, we will not be liable for any actions, claims, costs, expenses, or other liabilities that may arise due to unauthorized use or misuse of such information.</p>

        <h2>Definitions</h2>
        <p class="para">As used herein:</p>
        <p class="para"><span style={{color: "#04DA9A"}}>*</span> "LLP", "AMEXA", "Ourselves", "Us", "Our", and "We" refer to the owners, operators, employees, directors, officers, agents, insurers, suppliers, and attorneys of the Platform.</p>
        <p class="para"><span style={{color: "#04DA9A"}}>*</span> "Client", "Customer", "User", "You", and "Your" refer to a resident of India who is at least 18 years of age.</p>
        <p class="para"><span style={{color: "#04DA9A"}}>*</span> "Virtual Currency" or "VCs" refers to cryptocurrencies (digital money) issued and controlled by their developers and used among members of a specific virtual community, including but not limited to Bitcoin, NEO, Ether, Litecoin, Ripple, Dash, etc. The term “Virtual Currency” or “VC” herein includes only those cryptocurrencies that the LLP, in its sole discretion, decides to facilitate through the Platform.</p>
        <p class="para">Save and except as required under applicable law or force majeure events, as long as you agree to and comply with these Terms of Use, AMEXA grants you a limited, personal, non-exclusive, non-transferable, non-sub-licensable, revocable right to access and use the Platform and its services in accordance with these Terms.</p>
        <p class="para">You represent and warrant that you:</p>
        <p class="para"><span style={{color: "#04DA9A"}}>(a)</span> are at least 18 years old and competent to form a binding contract in India,</p>
        <p class="para"><span style={{color: "#04DA9A"}}>(b)</span> are an individual with full legal capacity and authority to enter into these Terms, and</p>
        <p class="para"><span style={{color: "#04DA9A"}}>(c)</span> have not previously been suspended or removed from using our services or the Platform.</p>

        <h2>Use of the Information</h2>
        <p class="para">We may use and share the information provided by or collected from you in the following ways: To provide and improve the services offered to you through the platform; To undertake KYC checks and verifications; To establish and verify your identity; To conduct research and analysis to detect, prevent, mitigate, and investigate fraudulent or illegal activities; To analyze how the platform is used, diagnose service or technical problems, and maintain security; To help you efficiently use and access the platform; To send you notifications, updates, and newsletters; To monitor aggregate metrics such as the total number of viewers, visitors, traffic, and demographic patterns; To enable us to comply with any legal and regulatory obligations or requisition requests; To enforce our legal rights against you; To complete know-your-customer requirements and onboarding. We will use and retain your information for such periods as necessary to comply with any applicable laws, our legal obligations, resolve disputes, record-keeping, and enforce this agreement.</p>
     
        <h2>Sharing and Disclosure of Information</h2>
        <p class="para">We may disclose your information to third parties for the purposes mentioned under Section 2 above, i.e., to perform KYC checks, provide you with customer support facilities, to comply with our legal obligations, including the prevention of money laundering, to enforce our terms of use, to facilitate our marketing and advertising activities, to conduct analysis, to provide you services, and to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to the platform.</p>
        <p class="para">In the event that an LLP is merged with or acquired by another LLP, or in the case of reorganization or restructuring of business, we and our affiliates will share your personal information, wholly or in part, with another business entity.</p>
        <p class="para">We will share your information with third parties only in such a manner as described below: We may share your information with third parties including third-party service providers, agents, partners, etc., and in such an event, the third parties’ use of your information will be bound by obligations to protect the confidentiality of your information. We may store information in locations outside the direct control of the LLP (for instance, on servers or databases co-located with hosting providers), and your use of the platform shall constitute consent for the same. We may disclose your information to any member of our related or group companies, including but not limited to, our subsidiaries, our ultimate holding LLP and its subsidiaries, as the case may be, and your use of the platform shall constitute consent for the same. We may disclose your information if we are under a duty to do so to comply with any legal obligation or an order from the government and/or a statutory authority, or to enforce or apply our terms of use, or assign such information in the course of corporate divestitures, mergers, or to protect the rights, property, or safety of us, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.</p>

        <h2>Storage, Security, and Disclosure of Information</h2>
        <p class="para">We use commercially reasonable safeguards to preserve the integrity and security of your information and data against loss, theft, unauthorized access, disclosure, reproduction, use, or amendment. All information you provide to us is stored on our secure servers within India. We may also store/move your data on our servers outside India, if required at our discretion. You hereby consent to your data being stored outside India as well. By submitting your information on our platform, you agree to the handling of your information by the LLP in a manner as stated under this policy and the AML policy. We assume no liability for any disclosure of information due to errors in transmission, unauthorized third-party access, or other acts of third parties, or acts or omissions beyond our reasonable control, and you agree that you will not hold us responsible for any breach of security unless such breach has been caused as a direct result of our gross negligence or wilful default. In using the platform, you accept the inherent security implications of data transmission over the internet, and the world wide web cannot always be guaranteed as completely secure. Therefore, your use of the platform will be at your own risk.</p>

        <h2>Use of Information</h2>
        <p class="para">By using the platform, you will be able to see the services offered/facilitated by us. You may have an interest or intention to avail such services or have a general query with respect to the services available on the platform. Accordingly, you may contact us. By contacting us in any way and providing us your phone number and contact details, you thereby give us the unequivocal right to: Contact you through various mediums, including but not limited to phone calls, emails, etc., or make a phone call to you to discuss your query or request. Contact you through phone or message notwithstanding the fact that you may have registered under the Telecom Regulatory Authority of India regulations as a fully blocked or partially blocked customer. It is further clarified that we shall only make solicited phone calls or messages. Share the contact details provided by you with the service provider, agent, or representative available on or through the platform who may sufficiently answer or respond to your query, including but not limited to third-party service providers. We understand that your contact details are important to you, and we shall bind the service providers/agents/affiliates that we share your contact details with, to the same level of protection as required under applicable laws.</p>

        <h2>Non-Disclosure of Information</h2>
        <p class="para">You may choose not to provide identifiable information through the platform, in which case you may not be allowed to access certain features/parts of the platform. If you are a registered user, you may update, edit, or correct your account information and email preferences at any time by logging into your account. If you believe that any of your information held by us is inaccurate, you may write to our Grievance Officer (“GO”) as detailed under Section 10. We will respond to your request within a period of thirty (30) days. It is your responsibility to ensure that any information you provide us remains accurate and updated.</p>

        <h2>Links to Third-Party Sites</h2>
        <p class="para">Our platform may contain links to and from the platforms of our partner networks, affiliates, and other third parties. The inclusion of a link does not imply any endorsement by us of the third-party platform, its provider, or the information on the third-party platform. If you follow a link to any of these platforms, please note that these platforms may be governed by their own privacy policies, and we disclaim all responsibility or liability with respect to these policies or the platforms. Please check these policies before submitting any information to these platforms.</p>

        <h2>Restrictions and Liabilities</h2>
        <p class="para">We are liable only for any recommendations made by our authorized person through the platform. We are not liable for any recommendations posted by any third party.</p>

        <h2>Severability and Exclusion</h2>
        <p class="para">We have taken every effort to ensure that this policy adheres to applicable laws. The invalidity or unenforceability of any part of this policy shall not prejudice or affect the validity or enforceability of the remainder of this policy. This policy does not apply to any information other than the information collected by the LLP through the platform. This policy shall be inapplicable to any unsolicited information you provide us through this platform or through any other means or public channels. All unsolicited information shall be deemed non-confidential, and the LLP shall be free to use or disclose such information without any limitations.</p>

        <h2>Changes to the Policy</h2>
        <p class="para">We may update this policy from time to time with or without notice to you. We encourage you to periodically review the policy for the latest information on our privacy practices.</p>

        <h2>Contact</h2>
        <p class="para">Please address your grievances, feedback, or questions regarding the collection, processing, usage, disclosure, and security of your information in writing to support@amexa.co or to 1101 - Aspect Tower, Executive Towers, Business Bay, Dubai, UAE or Hüseyin Özer İş Merkezi Selahattin Pınar Aralığı Sk.No:3 Kat:4 D:8 Mecidiyeköy,Şişli, İstanbul</p>
            </div>
            </div>

            <Footer />

        </>
    )
}