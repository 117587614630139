import React,{useState} from "react";
import Header from "./HomeComp/Header";
import { isOtp } from "./redux/helpers/form-validator.functions";
import { useDispatch, useSelector } from "react-redux";
import { opt_forget_verify } from "./redux/actions/authActions";
import { Link } from "react-router-dom";
import { N_resendOTP } from "./redux/helpers/api_functions_new";
import { NotificationManager } from "react-notifications";
export default function FOTP(props) {
  const { otp_send, user } = useSelector((state) => state.AuthReducer);
  const dispatch = useDispatch();
  if (!otp_send) props.history.push("/login");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState();
  const handleProceedSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
        opt_forget_verify(
        otp,
        user.email,
        () => props.history.push("/update-password"),
        () => setLoading(false)
      )
    );
  };
  return (
    <>
      <Header {...props} />
      <div className="container-fluid register_bg">
        <div className="row no-gutter">
        <div class="col-md-7 d-none d-lg-block login_left_bg p-0 ">
            
          </div>
          <div className="col-md-5">
            <div style={{minHeight:'100vh'}} class="d-flex align-items-center flex-column ">


        <div className="security_header ">
          <div className="security_head">
            <div className="sec_circle bg_circle shead-circle">
              <i className="fas fa-dot-circle"></i>
              <span color="#ffffff" className="email_span">
                Email
              </span>
            </div>
          </div>
        </div>
        <div className="container ">
          <div className="row">
            <div className="col-12 col-md-12 col-sm-12 m-auto login_window signupform cstm_form">
              <form
                className="signupform mdfthemetxt"
                onSubmit={handleProceedSubmit}
                id="otp_form"
              >
                <div>
                <div className="d-flex justify-content-center mb-5">
                  <Link to="/">
              <img src="/img/logo.png" style={{width:'80px'}}  />
              </Link>
            </div>
                  <h3>Check your inbox for verification mail</h3>
                </div>
                <div className="form-group">
                  <small id="msg" style={{ fontSize: "15px" }}>
                    Error message
                  </small>
                </div>
                <div className="form-group">
                  <div>
                    An OTP has sent your email
                    <br />
                    <strong>{user.email}</strong>. Don't see it? check your spam
                    folder.
                    <br /> <br />
                  </div>
                  <div className="input-group">
                    <input
                      type="text"
                      name="user_otp"
                      className="form-control"
                      value={otp}
                      id="user_otp"
                      maxLength={6}
                      onChange={(e) => {
                        setOtp(e.target.value);
                        isOtp(e.target.value);
                      }}
                      placeholder="Enter Your OTP"
                    />
                    <i
                      className="fas fa-check-circle"
                      style={{ top: "16px" }}
                    ></i>
                    <i
                      className="fas fa-exclamation-circle"
                      style={{ top: "16px" }}
                    ></i>
                     <span className="input-group-text">
                            <div className="">
                              <div className="">
                                <button
                                  className="btn border-0 text-primary border-start rounded-0"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    N_resendOTP(user.email).then(
                                      (res) => {
                                        if (res.status == 200) {
                                          NotificationManager.success(
                                            res.message
                                          );
                                        } else {
                                          NotificationManager.error(res.message);
                                        }
                                      }
                                    );
                                  }}
                                >
                                  Resend
                                </button>
                              </div>
                            </div>
                          </span>
                    <small>Error message</small>
                  </div>
                  <button type="submit" id="proceed_btn" className="login_btn px-5 mt-4">
                    Verify
                  </button>
                </div>
                {loading ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}
              </form>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>

      </div>


    </>
  );
}
